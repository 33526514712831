import { render, staticRenderFns } from "./_idSection.vue?vue&type=template&id=35d009ff&scoped=true&"
import script from "./_idSection.vue?vue&type=script&lang=js&"
export * from "./_idSection.vue?vue&type=script&lang=js&"
import style0 from "./_idSection.vue?vue&type=style&index=0&id=35d009ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d009ff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,BannerEventSaleSection: require('/usr/src/app/components/home/sale/BannerEventSaleSection.vue').default,SectionSale: require('/usr/src/app/components/home/sale/SectionSale.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
