//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import cookiesMixin from '@/mixins/cookies-mixin'

export default {
  mixins: [cookiesMixin],

  created () {
    this.setCookies()
  },

  async mounted () {
    if (this.$auth.loggedIn) {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission()
      } else {
        await this.getTokens()
      }
    }
  },

  methods: {
    ...mapActions('User', ['setFCMToken']),

    async getTokens () {
      await this.$fire.messaging
        .getToken({
          vapidKey:
            'BBS4gqjbnK_cr2159NmljE8QOd5PCGMGTNSX6MtUxxQeVmEieOv8SVNkuYpJQzY7M6wbtLa7FDMlbyR_EEmkJ5Y'
        })
        .then((currentToken) => {
          if (currentToken) {
            const payload = {
              data: {
                fcm_token: currentToken,
                platform: 'web',
                application: 'paninti-store'
              }
            }
            this.setFCMToken(payload)
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.'
            )
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
          // ...
        })
    }
  }
}
