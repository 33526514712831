//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import VClamp from 'vue-clamp'
import panstoreMixin from '@/mixins'
import {
  fileVariants,
  getDefaultImageFromFiles,
  getDefaultImgProductOnError
} from '@/utils/helpers/file-helper'
import {
  formatRupiah,
  generateRandomString
} from '@/utils/helpers/string-helper'
import { productImageNames } from '@/utils/helpers/product-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
export default {
  components: { VClamp },
  mixins: [panstoreMixin],
  computed: {
    ...mapState('Cart', ['selectedProduct', 'selectedVariant']),
    productImageNames () {
      return productImageNames
    },
    fileVariants () {
      return fileVariants
    },
    indexVariant () {
      if (this.selectedVariant) {
        return this.selectedProduct.details.findIndex((detail) => {
          return detail.id === this.selectedVariant
        })
      }
      return -1
    },
    productStore () {
      return this.selectedProduct ? this.selectedProduct.store : null
    },
    variantSelected () {
      if (this.selectedProduct) {
        const variant = this.selectedProduct.details.filter((variant) => {
          return variant.id === this.selectedVariant
        })[0]
        return variant && variant.name && variant.name !== undefined
          ? variant.name
          : ''
      } else {
        return ''
      }
    }
  },
  methods: {
    generateRandomString,
    formatRupiah,
    getDefaultImgProductOnError,
    getDefaultImageFromFiles,
    isObjectNotEmptyOrNull
  }
}
