import { StatusCodes } from 'http-status-codes'

export const getResponseErrorMessage = (error) => {
  if (error.response) {
    return error.response.data.message
  }

  return error.message
}

export const getResponseErrorStatus = (error) => {
  if (error.response) {
    return error.response.status
  }

  return error.statusCode || StatusCodes.INTERNAL_SERVER_ERROR
}

export const isResponseInternalServerError = (error) => {
  if (!error.response) {
    return true
  }

  return error.response && error.response.status >= 500 && error.response.status < 600
}
