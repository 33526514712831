//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins/images-mixin.js'
import { storeTypes, storeSimpleType } from '@/utils/helpers/store-helper'

export default {
  mixins: [mixins],

  props: {
    partner: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    storeTypes () {
      return storeTypes
    },
    storeSimpleType () {
      return storeSimpleType
    },
    isBrandPartner () {
      return this.partner.type === this.storeTypes.brand.value || false
    }
  },

  methods: {
    onCardPartnerRelatedActionClicked (slug) {
      this.$router.push(`/partner/${slug}`)
    }
  }
}
