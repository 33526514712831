const ALPHANUMERIC_CHARS =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const ALL_CHARS = ALPHANUMERIC_CHARS + '!@#$%^&*()_+?><:{}[]'

export const generateRandomString = (limit, type = 'all') => {
  const chars = type === 'alphanumeric' ? ALPHANUMERIC_CHARS : ALL_CHARS
  let result = ''

  for (let i = 0; i < limit; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return result
}

export const handleFormatRupiah = (amount = 0, prefix = 'Rp') => {
  // Remove any non-digit characters except comma
  const numberString = amount.toString().replace(/[^,\d.]/g, '')
  const [integerPart, fractionalPart] = numberString.split('.')

  // Calculate the initial segment length
  const initialSegmentLength = integerPart.length % 3

  // Format the integer part with dots as thousand separators
  let rupiah = integerPart.substr(0, initialSegmentLength)
  const thousands = integerPart.substr(initialSegmentLength).match(/\d{3}/gi)

  if (thousands) {
    const separator = initialSegmentLength ? '.' : ''
    rupiah += separator + thousands.join('.')
  }

  // Include the fractional part if it exists
  rupiah = fractionalPart !== undefined ? `${rupiah},${fractionalPart}` : rupiah

  // Add the prefix if provided
  return prefix ? `${prefix}${rupiah}` : rupiah
}

export const formatRupiah = (amount = 0, prefix = 'Rp') => {
  if (!amount || (typeof value === 'number' && amount < 0)) {
    amount = 0
  }

  const parts = amount.toString().split('-')
  return parts
    .map((part) => {
      return handleFormatRupiah(part, prefix)
    })
    .join(' - ')
}

export const formatPriceWithoutPrefix = (amount = 0) => {
  // Remove any non-digit characters except comma
  const numberString = amount.toString().replace(/[^,\d]/g, '')
  const [integerPart, fractionalPart] = numberString.split(',')

  // Calculate the initial segment length
  const initialSegmentLength = integerPart.length % 3

  // Format the integer part with dots as thousand separators
  let rupiah = integerPart.substr(0, initialSegmentLength)
  const thousands = integerPart.substr(initialSegmentLength).match(/\d{3}/gi)

  if (thousands) {
    const separator = initialSegmentLength ? '.' : ''
    rupiah += separator + thousands.join('.')
  }

  // Include the fractional part if it exists
  rupiah = fractionalPart !== undefined ? `${rupiah},${fractionalPart}` : rupiah

  // Add the prefix if provided
  return rupiah
}

export const isUuid = (param) => {
  // Regular expression to match UUID v4 format
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regex.test(param);
}
