//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [mixins],

  props: {
    type: {
      type: String,
      default: 'brand'
    }
  },

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },

      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0,

      mainProducts: []
    }
  },

  computed: {
    ...mapState('Product', ['product']),
    ...mapState('User/address', ['location']),

    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 427
    }
  },

  watch: {
    location () {
      this.getOtherProducts()
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.getOtherProducts()
  },

  methods: {
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },

    async getOtherProducts () {
      const cityId = localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location')).city_id
        : 155

      const payload = {
        where_has: [
          {
            relation: this.type
          }
        ],
        where_not_in: [['products.slug', [this.$route.params.slug]]],
        order_by: {
          created_at: 'desc'
        },
        city_id: cityId,
        per_page: 10
      }

      if (this.type === 'brand' && this.product.brand) {
        payload.where_has[0] = {
          ...payload.where_has[0],
          where: {
            'brands.id': this.product.brand.id
          }
        }
      } else {
        payload.where_has[0] = {
          ...payload.where_has[0],
          where: {
            'stores.id': this.product.store.id
          }
        }
      }

      try {
        const respStore = await this.$store.dispatch('Product/getHomeProducts', {
          data: payload
        })

        this.mainProducts = respStore.data.data.products.data
      } catch (error) {
        const sectionTypeName = this.type === 'brand' ? 'brand' : 'toko'

        this.$message.error('Gagal mendapatkan data produk lain dari ' + sectionTypeName + ' Partner')
      }
    }
  }
}
