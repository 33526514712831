//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imagesMixin from '@/mixins/images-mixin'
import {
  formatRupiah,
  generateRandomString
} from '@/utils/helpers/string-helper'
import { variantNames } from '@/utils/helpers/product-helper'
import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'

export default {
  mixins: [imagesMixin],
  props: {
    affiliateCommissionHistory: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      listCommissionProductsCollapse: ['1']
    }
  },

  computed: {
    variantNames () {
      return variantNames
    },
    affiliateCommissionOrder () {
      return this.affiliateCommissionHistory.order || null
    },
    commissionHistoryCreatedAt () {
      return this.affiliateCommissionOrder
        ? this.affiliateCommissionOrder.created_at
        : null
    }
  },

  methods: { generateRandomString, formatDateWithTimezone, formatRupiah }
}
