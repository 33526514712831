import Partner from '@/services/Partner/'
export const state = () => ({
  loading: {},
  partner: {},
  partners: {},
  showcase: [],
  relatedStores: [],
  totalRelatedStores: 0
})

export const mutations = {
  setLoading (state, payload) {
    state.loading = Object.assign(state.loading, {
      ...payload
    })
  },
  setPartner (state, payload) {
    state.partner = payload
  },
  setPartners (state, payload) {
    const { data, config } = payload

    if (config && config.paginated) {
      if (state.partners.data) {
        const oldData = state.partners.data

        data.forEach((dt) => {
          const oldIdx = oldData.findIndex((oldDt) => {
            return dt.id === oldDt.id
          })

          if (oldIdx > -1) {
            oldData.splice(1, oldIdx)
          }
        })
        const mergeData = [
          ...oldData,
          ...data
        ]

        const editedPartners = {
          ...payload,
          data: mergeData
        }

        state.partners = editedPartners
      } else {
        state.partners = payload
      }
    } else {
      state.partners = payload
    }
  },
  setShowcase (state, payload) {
    state.showcase = payload
  },
  setRelatedStores (state, payload) {
    state.totalRelatedStores = payload.totalRelatedStores
    if (payload.firstPage) {
      state.relatedStores = payload.relatedStores
    } else {
      state.relatedStores = state.relatedStores.concat(payload.relatedStores)
    }
  },
  setPartnersLoading (state, payload) {
    state.partners = {
      ...state.partners,
      loading: { ...state.partners.loading, [payload.type]: payload.value }
    }
  }
}

export const actions = {
  showPartner ({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('setLoading', {
        showPartner: true
      })
      Partner.showPartner(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        id
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setPartner', response.data.data.store)
          }
          commit('setLoading', {
            showPartner: false
          })
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('setLoading', {
            showPartner: false
          })
          reject(error)
        })
    })
  },
  getPartners ({ commit }, payload) {
    if (payload.data.search) {
      return new Promise((resolve, reject) => {
        Partner.getPartners(
          {
            loggedIn: this.$auth.loggedIn,
            value: this.$auth.loggedIn
              ? this.$auth.getToken('local')
              : this.$cookies.get('session_id') || ''
          },
          { data: { ...payload.data } }
        )
          .then((response) => {
            if (response.status === 200) {
              const objectWithoutKey = (object, key) => {
                const { [key]: deletedKey, ...otherKeys } = object
                return otherKeys
              }
              if (payload.setValue) {
                commit('setPartners', {
                  data: response.data.data.stores.data,
                  config: objectWithoutKey(response.data.data.stores, 'data')
                })
              }
            }
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    } else {
      commit('setPartners', {})
    }
  },
  getNearestPartners ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Partner.getNearestPartners(
        this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : {
            loggedIn: this.$auth.loggedIn,
            value: this.$cookies.get('session_id') || ''
          },
        payload
      )
        .then((response) => {
          const paginated = payload.params.paginated || false
          const stores = response.data.data.stores
          const data = paginated ? stores.data : stores

          commit('setPartners', {
            data,
            config: {
              paginated,
              current_page: paginated ? stores.current_page : 1,
              last_page: paginated ? stores.last_page : 1
            }
          })

          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getRelatedPartners ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Partner.getRelatedPartners(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setRelatedStores', {
              relatedStores: response.data.data.stores.data,
              totalRelatedStores: response.data.data.stores.total,
              firstPage: response.data.data.stores.current_page === 1
            })
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getShowcasePartner ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', {
        showcase: true
      })
      Partner.getShowcasePartner(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setShowcase', response.data.data.showcases)
          }
          commit('setLoading', {
            showcase: true
          })
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('setLoading', {
            showcase: true
          })
          reject(error)
        })
    })
  },
  getProductRecommendations ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Partner.getProductRecommendations(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
