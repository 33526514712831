//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import windowResizeMixin from '@/mixins/window-resize-mixin'

import {
  fileVariants,
  getDefaultImageFromFiles,
  getDefaultImgBannerOnError
} from '@/utils/helpers/file-helper'
import { eventSaleFileNames } from '@/utils/helpers/event-sale-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    dataBanner: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    eventSale () {
      return this.dataBanner.length > 0 ? this.dataBanner[0] : null
    },

    bannerDesktop () {
      if (this.dataBanner.length <= 0) {
        return null
      }

      return getDefaultImageFromFiles(
        this.dataBanner[0].files,
        eventSaleFileNames.banner,
        fileVariants.thumbnail,
        true
      )
    },

    bannerPhone () {
      if (this.dataBanner.length <= 0) {
        return null
      }

      return getDefaultImageFromFiles(
        this.dataBanner[0].files,
        eventSaleFileNames.bannerMobile,
        fileVariants.thumbnail,
        true
      )
    }
  },

  methods: {
    getDefaultImgBannerOnError
  }
}
