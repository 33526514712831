import { render, staticRenderFns } from "./CatalogListPartner.vue?vue&type=template&id=1bede91e&scoped=true&"
import script from "./CatalogListPartner.vue?vue&type=script&lang=js&"
export * from "./CatalogListPartner.vue?vue&type=script&lang=js&"
import style0 from "./CatalogListPartner.vue?vue&type=style&index=0&id=1bede91e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bede91e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconFilter: require('/usr/src/app/components/icons/IconFilter.vue').default,CardPartnerRelated: require('/usr/src/app/components/home/products/catalogue/CardPartnerRelated.vue').default,SkeletonRelated: require('/usr/src/app/components/home/products/catalogue/SkeletonRelated.vue').default})
