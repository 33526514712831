//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
import { voucherTypes } from '@/utils/helpers/voucher-helper'
import { defaultImages, getDefaultImageFromFiles, getDefaultImgOnError } from '@/utils/helpers/file-helper'
export default {
  props: {
    voucher: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    defaultImages () {
      return defaultImages
    },
    classes () {
      const arrClass = []

      if (this.voucherType === this.voucherTypes.discount.value) {
        arrClass.push('voucher-discount')
      } else {
        arrClass.push('voucher-free-shipping')
      }

      return arrClass
    },
    voucherTypes () {
      return voucherTypes
    },
    voucherType () {
      return this.voucher.type
    },
    voucherTypeImg () {
      if (this.voucherType === this.voucherTypes.discount.value) {
        return '/img/icon/account/voucher/icon-voucher-disc.svg'
      } else {
        return '/img/icon/account/voucher/icon-voucher-shipping.svg'
      }
    },
    voucherReductionValue () {
      let text = ''

      if (this.voucherType === this.voucherTypes.discount.value) {
        text = `${this.voucher.reduction_value}%`
      } else {
        text = formatRupiah(this.voucher.reduction_value, 'Rp')
      }

      return text
    },
    voucherFromImg () {
      if (this.voucher.voucher_from !== 'paninti') {
        return getDefaultImageFromFiles(this.voucher.voucherable.files)
      } else {
        return '/img/icon/account/voucher/icon-paninti-voucher.svg'
      }
    }
  },
  methods: {
    formatRupiah,
    formatDateWithTimezone,
    getDefaultImgOnError
  }
}
