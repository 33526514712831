export const simpleProductVariantName = 'Default'

export const productImageNames = {
  image: 'image'
}

export const cardProductPurposes = {
  general: 'general',
  affiliate: 'affiliate',
  flashsale: 'flashsale',
  addCart: 'add-cart'
}

export const productTypes = {
  simple: 'simple',
  combination: 'combination'
}

export const variantNames = {
  default: {
    name: 'Default',
    value: 'default'
  }
}

export const splitProductPrice = (price) => {
  if (!price) {
    return ['0']
  }

  return price.toString().split('-', 2)
    .map(price => Number(price))
}

export const isOriginalPriceAndDiscountedPriceEqual = (product) => {
  return product.price === product.discounted_price
}
