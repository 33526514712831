import { render, staticRenderFns } from "./EventSaleOnNotSearch.vue?vue&type=template&id=767fa5aa&scoped=true&"
import script from "./EventSaleOnNotSearch.vue?vue&type=script&lang=js&"
export * from "./EventSaleOnNotSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767fa5aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionSalePreview: require('/usr/src/app/components/home/sale/SectionSalePreview.vue').default,SectionSale: require('/usr/src/app/components/home/sale/SectionSale.vue').default,ProductCardSkeletonLoading: require('/usr/src/app/components/base/ProductCardSkeletonLoading.vue').default})
