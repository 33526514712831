//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'

export default {
  props: {
    filterModel: {
      type: Object,
      required: true
    },

    filterData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes(),
        data: {
          category: [],
          location: [],
          price: {
            from: 0,
            until: 5000000
          },
          rating: [],
          variant: {}
        }
      })
    },

    filterLabel: {
      type: Object,
      default: () => ({
        category: 'Kategori'
      })
    }
  },

  data () {
    return {
      actionButtonLoading: false
    }
  },

  methods: {
    ...mapMutations('Product/ProductsFilterMobile', [
      'SET_PRODUCTS_FILTER_MOBILE_IS_OPEN'
    ]),

    populateRouteQueryToFilterModel (queries = {}) {
      this.filterModel.activeCategoryTreeItems = queries.saleProductsCategories
        ? queries.saleProductsCategories.split(',')
        : []

      this.filterModel.location = queries.saleProductsLocations
        ? queries.saleProductsLocations.split(',')
        : []

      this.filterModel.price = queries.saleProductsPriceRange
        ? queries.saleProductsPriceRange.split(',')
        : [0, 10000000]

      this.filterModel.rating = queries.saleProductsRating
        ? queries.saleProductsRating.split(',').map(rating => Number(rating)).filter(rating => rating)
        : []
    },

    handleBtnOpenProductsFilterMobileOnClick () {
      this.populateRouteQueryToFilterModel(this.$route.query)
      this.SET_PRODUCTS_FILTER_MOBILE_IS_OPEN(true)
    },

    handleProductsSideFilterCategoryTreeNodeOnClick (data) {
      if (!this.filterModel.activeCategoryTreeItems.includes(data.slug)) {
        this.filterModel.activeCategoryTreeItems = [data.slug]
      } else {
        this.filterModel.activeCategoryTreeItems = []
      }
    },

    handleBtnResetFilterOnClick () {
      this.$refs.productsFilterMobileDrawer.resetRouteQueryFilter()
      this.closeProductsFilterMobileDrawer()
    },

    handleBtnApplyFilterOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          saleProductsCategories: this.filterModel.activeCategoryTreeItems.length > 0
            ? this.filterModel.activeCategoryTreeItems.join(',')
            : null,
          saleProductsLocations: this.filterModel.location.length > 0
            ? this.filterModel.location.join(',')
            : null,
          saleProductsPriceRange: this.filterModel.price.length > 0
            ? this.filterModel.price.join(',')
            : null,
          saleProductsRating: this.filterModel.rating.length > 0
            ? this.filterModel.rating.join(',')
            : null
        })
      })

      this.closeProductsFilterMobileDrawer()
    },

    closeProductsFilterMobileDrawer () {
      this.actionButtonLoading = true

      this.$nextTick(() => {
        setTimeout(() => {
          this.SET_PRODUCTS_FILTER_MOBILE_IS_OPEN(false)
          this.actionButtonLoading = false
        }, 500)
      })
    }
  }
}
