//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'

import mixins from '@/mixins'
import productCardSkeletonLoadingMixin from '@/mixins/product-card-skeleton-loading-mixin'

import { storeTypes } from '@/utils/helpers/store-helper'

export default {
  mixins: [mixins, productCardSkeletonLoadingMixin],

  props: {
    brand: {
      type: Object,
      default: () => ({})
    },
    storeType: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      dataProducts: [],
      showAll: false,
      page: 1,
      totalPage: 0,
      filterValue: 'distance',
      options: [
        {
          value: 'distance',
          label: 'Terdekat'
        },
        {
          value: 'price',
          label: 'Termurah'
        },
        {
          value: 'price-and-distance',
          label: 'Termurah dan Terdekat'
        }
      ],
      loading: false
    }
  },

  computed: {
    ...mapState('Product', ['product']),
    ...mapState('User/address', ['location', 'userAddress', 'isCreated']),
    storeTypes () {
      return storeTypes
    }
  },

  watch: {
    location () {
      this.page = 1
      this.totalPage = 0
      this.getDataProduct()
    },
    filterValue () {
      this.page = 1
      this.totalPage = 0
      this.getDataProduct()
    }
  },

  created () {
    this.page = 1
    this.totalPage = 0
    this.getDataProduct()
  },

  methods: {
    ...mapActions('Product', ['getProductNearest']),
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar', 'getEmptyCarts']),
    async getDataProduct () {
      try {
        this.loading = true
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155
        const resp = await this.getProductNearest({
          params: {
            product_id: this.product.id,
            page: this.page,
            per_page: 2,
            city_id: cityId,
            order_by: this.filterValue
          }
        })

        this.dataProducts = resp.data.data.products || []

        if (this.dataProducts.length === 0 || this.storeType === 'brand_partner') {
          this.$emit('show-brand', true)
        }
      } catch (error) {
        this.$emit('show-brand', true)
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }

      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })

        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }

      try {
        await this.getCartsNavbar({ params: payload })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
