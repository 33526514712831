//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { mapActions, mapMutations, mapState } from 'vuex'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { notificationTypes } from '@/utils/helpers/notification-helper'
export default {
  mixins: [panstoreMixin],
  data () {
    return {
      loading: true,
      dataNotif: {
        current_page: 0,
        last_page: 0,
        data: []
      },
      tabs: 'all'
    }
  },
  computed: {
    ...mapState('Base', ['dataBreadCrumbs']),
    ...mapState('User', ['notifSummary']),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    },
    filterNotifTypes () {
      return Object.entries(notificationTypes).map(([_key, value]) => {
        return {
          label: value.label,
          value: value.value
        }
      })
    }
  },
  watch: {
    '$route.query.tab' () {
      this.dataNotif = {
        current_page: 0,
        last_page: 0,
        data: []
      }
      this.loading = true
      this.tabs = this.$route.query.tab || 'all'
      this.handleGetData()
    }
  },
  mounted () {
    this.tabs = this.$route.query.tab || 'all'
    this.handleGetData()
  },
  methods: {
    ...mapActions('User', [
      'getNotification',
      'showNotification',
      'readAllNotification',
      'getSummaryNotification'
    ]),

    ...mapMutations('Base', ['setBreadCrumbs']),
    generateRandomString,
    getSummaryCountValue (item) {
      switch (item) {
        case 'transaction':
          return this.notifSummary.unread_count_transaction || 0
        case 'promotion':
          return this.notifSummary.unread_count_promotion || 0
        default:
          return this.notifSummary.unread_count || 0
      }
    },
    async getNotifications () {
      this.loading = true
      try {
        const resp = await this.getNotification({
          params: {
            type: this.$route.query.tab || undefined,
            page: this.dataNotif.current_page + 1,
            per_page: 10
          }
        })
        if (resp.status === 200) {
          if (this.dataNotif.current_page === 0) {
            this.dataNotif = resp.data.data.notifications
          } else {
            this.dataNotif = {
              ...resp.data.data.notifications,
              data: this.dataNotif.data.concat(
                resp.data.data.notifications.data
              )
            }
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getIcon (name) {
      switch (name) {
        case 'Pembayaran':
          return 'payment'
        case 'Pesanan Dikemas':
          return 'order-packaging'
        case 'Pengiriman':
          return 'order-shipping'
        case 'Order Sampai':
          return 'order-done'
        case 'Order Selesai':
          return 'order-done'
        case 'Order Dibatalkan':
          return 'order-done'
        case 'Diskon':
          return 'discount'
        case 'Sale':
          return 'discount'
        case 'Event Sale':
          return 'event-sale'
        case 'Voucher':
          return 'voucher'
        case 'Poin':
          return 'voucher'
        case 'Afiliasi':
          return 'affiliate'
      }
    },
    handleGetData () {
      this.getNotifications()
      this.getSummaryNotification()
    },
    changeTab (tab) {
      this.$router.push({
        query: {
          tab: tab !== 'all' ? tab || undefined : undefined
        }
      })
    },
    async handleClickNotif ({ id, link, notification_type: notifType }) {
      let redirect = link
      await this.showNotification(id)

      if (
        notifType.name === 'Afiliasi' &&
        this.checkObject(this.user) &&
        this.checkObject(this.user.affiliate)
      ) {
        redirect = '/account/affiliate'
      }
      window.location = redirect
    },
    async handleReadAll () {
      try {
        const resp = await this.readAllNotification({
          data: {
            type: this.tabs === 'transaction' ? 'transaction' : 'promotion'
          }
        })
        if (resp.status === 200) {
          this.dataNotif = {
            current_page: 0,
            last_page: 0,
            data: []
          }
          this.handleGetData()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
