var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-navigation bottom-navigation-container"},[_c('div',{staticClass:"navigation-wrapper"},_vm._l((_vm.navigationItems),function(item,index){return _c('a',{key:'bottom-navigation-' + index + '-' + item.name,staticClass:"navigation-item",class:{ active: _vm.checkNavigationItemActive(item) },on:{"click":function($event){return _vm.handleBottomNavigationItemOnClick(item)}}},[[_c('img',{staticClass:"navigation-icon",class:{
            'active': _vm.checkNavigationItemActive(item),
            'navigation-avatar': item.name === _vm.navigationItems.account.name
          },attrs:{"src":_vm.checkNavigationItemActive(item) ? item.iconActive : item.icon,"alt":("Icon " + (item.text))},on:{"error":function($event){item.name === _vm.navigationItems.account.name
              ? _vm.getDefaultUserAvatarOnError(
                $event,
                _vm.userLoggedIn.detail
                  ? _vm.userLoggedIn.detail.gender
                  : _vm.defaultUserGender.value
              )
              : null}}}),_vm._v(" "),_c('div',{staticClass:"navigation-text"},[_vm._v("\n          "+_vm._s(item.text)+"\n        ")]),_vm._v(" "),(item.hasBadgeCount)?_c('div',{staticClass:"badge-counter"},[_vm._v("\n          "+_vm._s(item.badgeCount > 99
              ? '99+'
              : Math.max(item.badgeCount, 0))+"\n        ")]):_vm._e()]],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }