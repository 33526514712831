import { render, staticRenderFns } from "./BrandSaleSection.vue?vue&type=template&id=009054e0&scoped=true&"
import script from "./BrandSaleSection.vue?vue&type=script&lang=js&"
export * from "./BrandSaleSection.vue?vue&type=script&lang=js&"
import style0 from "./BrandSaleSection.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./BrandSaleSection.vue?vue&type=style&index=1&id=009054e0&lang=scss&scoped=true&"
import style2 from "./BrandSaleSection.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009054e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Clamp: require('/usr/src/app/components/base/Clamp.vue').default})
