//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { fileVariants, getDefaultImageFromFiles, getDefaultStoreLogoOnError } from '@/utils/helpers/file-helper'
import { brandFileNames } from '@/utils/helpers/brand-helper'
import { storeTypes } from '@/utils/helpers/store-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    sessionId: {
      type: String,
      default: ''
    },

    dataEvent: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      windowWidth: process.client ? window.innerWidth : 0,
      brands: []
    }
  },

  computed: {
    ...mapState('Contents', ['eventSale']),

    storeTypes () {
      return storeTypes
    },
    fileVariants () {
      return fileVariants
    },
    brandFileNames () {
      return brandFileNames
    },

    haveBrands () {
      return this.brands.length > 0
    },

    dataEventExist () {
      return this.dataEvent && Object.keys(this.dataEvent).length
    }
  },

  async mounted () {
    await this.getOnSaleBrands()
  },

  methods: {
    getDefaultStoreLogoOnError,
    getDefaultImageFromFiles,

    ...mapActions('Brand', ['getListBrandsSale']),

    async getOnSaleBrands () {
      try {
        const resp = await this.getListBrandsSale({
          type: this.$route.name === 'sale-id' ? 'event-sale' : this.$route.name,
          payload: {
            data: {
              event_sale_session_id: this.sessionId,
              paginated: false
            }
          }
        })

        this.brands = resp.data.data.brands
      } catch (error) {
        this.$message.error('Gagal mendapatkan data brand sedang diskon')
      }
    },

    handleGoToBrandPage (brand) {
      const eventSlug = this.dataEventExist ? this.dataEvent.slug : this.$route.params.id

      window.location = `/sale/${eventSlug}/brand/${brand.slug}`
    }
  }
}
