import { render, staticRenderFns } from "./NotificationSection.vue?vue&type=template&id=2968ec7c&scoped=true&"
import script from "./NotificationSection.vue?vue&type=script&lang=js&"
export * from "./NotificationSection.vue?vue&type=script&lang=js&"
import style0 from "./NotificationSection.vue?vue&type=style&index=0&id=2968ec7c&lang=scss&scoped=true&"
import style1 from "./NotificationSection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2968ec7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/usr/src/app/components/base/Tab.vue').default,EmptyNotification: require('/usr/src/app/components/base/EmptyNotification.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default})
