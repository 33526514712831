//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { generateRandomString } from '@/utils/helpers/string-helper'
export default {
  data () {
    return {
      datas: [],
      dataLimit: 5,
      loading: true
    }
  },
  computed: {
    ...mapState('User/address', ['location'])
  },
  watch: {
    location () {
      this.getNewestProducts()
    }
  },
  mounted () {
    this.getNewestProducts()
  },
  methods: {
    generateRandomString,
    ...mapActions('Product', ['getBestSellerProducts']),
    async getNewestProducts () {
      this.loading = true

      try {
        const cityId = localStorage.getItem('location')
          ? JSON.parse(localStorage.getItem('location')).city_id
          : 155

        const resp = await this.getBestSellerProducts({
          params: {
            session_id: this.$cookies.get('session_id'),
            city_id: cityId,
            per_page: this.dataLimit
          }
        })

        this.datas = resp.data.data.products.data
      } catch (error) {
        this.$message.error('Gagal mendapatkan data dari produk terlaris')
      }

      this.loading = false
    }
  }
}
