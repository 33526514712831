//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
export default {
  mixins: [mixins],
  layout: 'account',
  middleware: ['user'],
  data () {
    return {
      breadCrumbs: [
        {
          path: '/'
        },
        {
          name: 'Voucher Saya'
        }
      ]
    }
  },
  computed: {
    myPoint () {
      return this.$auth.$state.user.paninti_point
    }
  }
}
