import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getVouchers (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/vouchers',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getVouchersByType (auth, payload) {
    return apiClient({
      method: 'post',
      url: `/v1/general/vouchers/${payload.type}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  checkVoucher (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/vouchers/check',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  showVoucher (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/vouchers/' + payload.voucherId,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getUserVoucher (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/vouchers',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getUserVoucherSummary (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/vouchers/summary',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
