//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixins from '@/mixins'

export default {
  mixins: [panstoreMixins],

  props: {
    eventSaleSection: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      windowWidth: process.client ? window.innerWidth : 0
    }
  },

  computed: {
    bannerHeader () {
      if (!this.eventSaleSection || !this.eventSaleSection.files) {
        return null
      }

      const bannerHeaders = this.eventSaleSection.files.filter((file) => {
        return file.name === 'header'
      })

      if (bannerHeaders.length > 0) {
        return bannerHeaders[0].value
      }

      return null
    },

    eventSaleSectionProducts () {
      if (!this.eventSaleSection || !this.eventSaleSection.event_sale_products) {
        return []
      }

      return this.eventSaleSection.event_sale_products.data
    },
    moreThanFiveProducts () {
      return this.eventSaleSection.event_sale_products.total > 5
    },
    normalDesktop () {
      return this.windowWidth >= 992
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    }
  }
}
