//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapActions } from 'vuex'

import pageMixin from '@/mixins/page-mixin'

import {
  assignResponseToDataModelData,
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes,
  defaultPaginationAttributes, resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { defaultDebounceTimes } from '@/utils/helpers/form-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'

export default {
  mixins: [
    pageMixin
  ],

  data () {
    return {
      pageDataLoading: true,
      eventSaleSession: null,
      brand: null,

      // START - Side Filter
      sideFilterModel: {
        activeCategoryTreeItems: [],
        activeCollapseNames: [
          'filter-category',
          'filter-location',
          'filter-price',
          'filter-rating'
        ],
        location: [],
        price: [
          0, 10000000
        ],
        rating: []
      },
      sideFilterData: {
        ...defaultNotPaginationAttributes()
      },
      sideFilterLabel: {
        category: 'Kategori Produk',
        price: 'Harga Produk'
      },
      // ENG - Side Filter

      // START - Event Sale Products
      sortFilterOptions: {
        bestSeller: {
          label: 'Terlaris',
          value: 'best_seller'
        },
        mostExpensive: {
          label: 'Termahal',
          value: 'most_expensive'
        },
        cheapest: {
          label: 'Termurah',
          value: 'cheapest'
        }
      },
      eventSaleProducts: {
        ...defaultPaginationAttributes(),
        perPage: 8,

        filter: {
          search: '',
          searchTemp: '',
          sort: ''
        }
      }
      // END - Event Sale Products
    }
  },

  computed: {
    paramId () {
      return this.$route.params.id
    },

    paramBrandId () {
      return this.$route.params.brandId
    },

    breadCrumb () {
      return [
        {
          path: '/'
        },
        {
          name: this.eventSale ? this.eventSale.name : 'Event Sale',
          path: `/sale/${this.eventSale ? this.eventSale.slug : 'event-sale'}`
        },
        {
          name: this.brand ? this.brand.name : 'Brand'
        }
      ]
    },

    eventSale () {
      return this.eventSaleSession ? this.eventSaleSession.event_sale : null
    }
  },

  watch: {
    '$route.query': {
      handler (value, oldVal) {
        if (value) {
          this.populateRouteQueryToEventSaleProducts(value)
        }

        if (!_.isEqual(value, oldVal)) {
          resetPaginationData(this.eventSaleProducts)
          this.getEventSaleProducts({
            resetData: true
          })
        }
      },
      deep: true
    }
  },

  mounted () {
    this.populateRouteQueryToEventSaleProducts(
      this.$route.query
    )

    Promise.allSettled([
      this.getPageData(),
      this.getProductsSideFilterData(),
      this.getEventSaleProducts()
    ])
  },

  methods: {
    ...mapActions('Contents', ['getOngoingEventSale']),
    ...mapActions('Brand', ['showBrand']),
    ...mapActions('Product/filter', ['getProductsSideFilter']),
    ...mapActions('Product', ['getProducts']),

    // START - Page Methods
    populateRouteQueryToEventSaleProducts (queries = {}) {
      this.eventSaleProducts.filter.search = queries.saleProductsSearch || ''
      this.eventSaleProducts.filter.searchTemp = queries.saleProductsSearch || ''

      this.eventSaleProducts.filter.sort = queries.saleProductsSort || ''

      this.sideFilterModel.activeCategoryTreeItems = queries.saleProductsCategories
        ? queries.saleProductsCategories.split(',')
        : []

      this.sideFilterModel.location = queries.saleProductsLocations
        ? queries.saleProductsLocations.split(',')
        : []

      this.sideFilterModel.price = queries.saleProductsPriceRange
        ? queries.saleProductsPriceRange.split(',')
        : [0, 10000000]

      this.sideFilterModel.rating = queries.saleProductsRating
        ? queries.saleProductsRating.split(',').map(rating => Number(rating)).filter(rating => rating)
        : []
    },

    async getPageData () {
      try {
        this.pageDataLoading = true

        await Promise.allSettled([
          this.getDetailEventSale(),
          this.getDetailBrand()
        ])
      } catch (error) {
        console.log(error)
      } finally {
        this.pageDataLoading = false
      }
    },

    async getDetailEventSale () {
      try {
        const response = await this.getOngoingEventSale({
          data: {
            where_has: [
              {
                relation: 'event_sale',
                where: {
                  type: 'event_sale',
                  slug: this.paramId
                }
              }
            ]
          }
        })

        this.eventSaleSession = response.data.data.event_sale_sessions.length > 0
          ? response.data.data.event_sale_sessions[0]
          : null

        if (!this.eventSaleSession) {
          this.setPageToErrorPage()
        }
      } catch (error) {
        console.log(error)

        this.setPageToErrorPage()
      }
    },

    async getDetailBrand () {
      try {
        const response = await this.showBrand(this.paramBrandId)

        this.brand = response.data.data.brand
      } catch (error) {
        console.log(error)

        this.setPageToErrorPage()
      }
    },
    // END - Page Methods

    // START - Event Sale Detail Brand Products Section Header
    handleEventSaleProductsFilterSearchOnInput: _.debounce(function (searchValue) {
      this.pushFilterIntoRouteQuery({
        saleProductsSearch: searchValue || null
      })
    }, defaultDebounceTimes.search),
    // END - Event Sale Detail Brand Products Section Header

    // START  - Event Sale Detail Brand Products Section Content
    // START - Side Filter
    async getProductsSideFilterData () {
      try {
        this.sideFilterData.loading = true

        const response = await this.getProductsSideFilter({
          params: {
            event_sale: this.paramId
          }
        })

        assignResponseToDataModelDataNotPaginated(this.sideFilterData, response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.sideFilterData.loading = false
      }
    },

    handleProductsSideFilterCategoryTreeNodeOnClick (data) {
      const saleProductsCategories = this.$route.query.saleProductsCategories
      if (!saleProductsCategories || !saleProductsCategories.includes(data.slug)) {
        this.pushFilterIntoRouteQuery({
          saleProductsCategories: data.slug || null
        })
        return
      }

      this.pushFilterIntoRouteQuery({
        saleProductsCategories: null
      })
    },

    handleProductsSideFilterLocationCheckboxGroupOnChange (data) {
      this.pushFilterIntoRouteQuery({
        saleProductsLocations: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterPriceSliderOnChange (data) {
      this.pushFilterIntoRouteQuery({
        saleProductsPriceRange: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterRatingCheckboxGroupOnChange (data) {
      this.pushFilterIntoRouteQuery({
        saleProductsRating: data.length > 0 ? data.join(',') : null
      })
    },

    handleBtnResetProductsSideFilterOnClick () {
      this.$refs.productsSideFilter.resetRouteQueryFilter()
    },
    // END - Side Filter

    // START - Event Sale Products
    // START - Event Sale Products Header
    handleSelectFilterSortProductsOnChange (value) {
      this.pushFilterIntoRouteQuery({
        saleProductsSort: value || null
      })
    },
    // END - Event Sale Products Header

    async getEventSaleProducts ({ resetData } = { resetData: false }) {
      try {
        this.eventSaleProducts.loading = true

        // Payload
        const smallestRating = this.sideFilterModel.rating.length > 0
          ? Math.min(...this.sideFilterModel.rating)
          : undefined

        const getPayload = {
          data: {
            paginated: this.eventSaleProducts.paginated,
            page: this.eventSaleProducts.page,
            per_page: this.eventSaleProducts.perPage,

            ...filterNonNullValues({
              search: this.eventSaleProducts.filter.search || null,
              category_slug: this.sideFilterModel.activeCategoryTreeItems.length > 0
                ? this.sideFilterModel.activeCategoryTreeItems
                : null,
              city: this.sideFilterModel.location.length > 0
                ? this.sideFilterModel.location
                : null,
              where_rating: smallestRating
                ? Array.from({ length: 6 - smallestRating }, (_, i) => (smallestRating + i).toString())
                : null
            }),

            event_sale: this.paramId,
            or_where_has: [
              {
                relation: 'brand',
                where_in: [
                  [
                    'slug',
                    [
                      this.paramBrandId
                    ]
                  ]
                ]
              }
            ]
          }
        }

        // Payload Sort
        switch (this.eventSaleProducts.filter.sort) {
          case this.sortFilterOptions.bestSeller.value:
            Object.assign(getPayload.data, {
              order_by: {
                total_sold_last_month: 'desc'
              }
            })
            break
          case this.sortFilterOptions.mostExpensive.value:
            Object.assign(getPayload.data, {
              order_by: {
                price: 'desc'
              }
            })
            break
          case this.sortFilterOptions.cheapest.value:
            Object.assign(getPayload.data, {
              order_by: {
                price: 'asc'
              }
            })
            break
        }

        // Payload Price Range
        if (this.sideFilterModel.price.length === 2 && this.sideFilterData.data.price) {
          if (
            this.sideFilterModel.price[0] !== this.sideFilterData.data.price.from ||
            this.sideFilterModel.price[1] !== this.sideFilterData.data.price.until
          ) {
            Object.assign(getPayload.data, {
              where_greater_than: {
                price: this.sideFilterModel.price[0]
              },
              where_lower_than: {
                price: this.sideFilterModel.price[1]
              }
            })
          }
        }

        const response = await this.getProducts(getPayload)

        assignResponseToDataModelData(this.eventSaleProducts, response.data.data.products, resetData)
      } catch (error) {
        console.log(error)
      } finally {
        this.eventSaleProducts.loading = false
      }
    },
    // END - Event Sale Products
    // END  - Event Sale Detail Brand Products Section Content

    // START - Others
    pushFilterIntoRouteQuery (query) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          ...query
        })
      })
    }
    // END - Others
  }
}
