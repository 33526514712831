var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"commission-history-item",on:{"click":_vm.handleClick}},[_c('div',{staticClass:"transaction-info"},[_c('div',{staticClass:"paninti-content-sm regular color-text-title paninti-mb-3"},[_vm._v("\n      "+_vm._s(_vm.item.balance_type === _vm.affiliateCommissionBalanceTypes.in.value
          ? ("No. Pesanan " + (_vm.item.order.reference))
          : ("Kode Penarikan " + (_vm.item.affiliate_balance_withdrawal.code)))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"transaction-info__desc paninti-mb-3",domProps:{"innerHTML":_vm._s(_vm.item.description)}}),_vm._v(" "),_c('div',{staticClass:"paninti-content-xs regular color-text-paragraph-alt"},[_vm._v("\n      "+_vm._s(_vm.item.created_at
          ? _vm.formatDateWithTimezone(_vm.item.created_at, {
            formatResult: 'DD/MM/YYYY HH:mm'
          })
          : '-')+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"earned-commission text-right"},[_c('div',{staticClass:"paninti-content-sm regular color-text-title"},[_vm._v("\n      Komisi\n      "+_vm._s(_vm.item.balance_type === _vm.affiliateCommissionBalanceTypes.in.value
          ? 'Diterima'
          : 'Ditarik')+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"paninti-h3",class:{
        'color-text-link-primary':
          _vm.item.balance_type === _vm.affiliateCommissionBalanceTypes.in.value,
        'color-text-link-secondary':
          _vm.item.balance_type === _vm.affiliateCommissionBalanceTypes.out.value
      }},[_vm._v("\n      "+_vm._s((_vm.item.balance_type === _vm.affiliateCommissionBalanceTypes.in.value
          ? '+'
          : '-') + _vm.formatRupiah(_vm.item.amount))+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }