import { apiClient, getHeaders } from '@/services/axiosConfig'

export default {
  getFilterCategories (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/categories',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getFilterBrands (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/brands',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getFilterVariants (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/product/variants',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getFilterCategoryPage (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/catalogs/category/side-filter/' + payload,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getFilterPage (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/catalogs/product/side-filter',
      params: {
        ...payload.params
      },
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  }
}
