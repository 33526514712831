export const maxNumberOfBankAccount = 3

export const userGenders = {
  male: {
    label: 'Laki-laki',
    label2: 'Pria',
    value: 'male'
  },
  female: {
    label: 'Perempuan',
    label2: 'Wanita',
    value: 'female'
  }
}

export const defaultUserGender = userGenders.female

export const userFileNames = {
  avatar: 'avatar'
}
