//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapActions } from 'vuex'
import _ from 'lodash'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'

export default {
  mixins: [mixins],

  props: {
    eventSaleSectionId: {
      type: String,
      default: null
    },

    eventSaleSessionId: {
      type: String,
      default: null
    },
    search: {
      type: String,
      default: ''
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      eventSaleSection: null,
      eventSaleProducts: {
        isLoading: false,
        page: 1,
        perPage: 10,
        totalPage: 0,
        total: 0,
        data: []
      },
      showResultText: false,
      sectionFilter: {},
      sectionFilterOptions: [
        {
          id: 1,
          label: 'Terlaris',
          value: 'total_sold_last_month',
          sorting: 'desc'
        },
        {
          id: 2,
          label: 'Termahal',
          value: 'price',
          sorting: 'asc'
        },
        {
          id: 3,
          label: 'Termurah',
          value: 'price',
          sorting: 'desc'
        }
      ],
      windowWidth: process.client ? window.innerWidth : 0
    }
  },

  computed: {
    bannerHeader () {
      if (!this.eventSaleSection || !this.eventSaleSection.files) {
        return null
      }

      const bannerHeaders = this.eventSaleSection.files.filter((file) => {
        return file.name === 'header'
      })

      if (bannerHeaders.length > 0) {
        return bannerHeaders[0].value
      }

      return null
    },
    normalDesktop () {
      return this.windowWidth >= 992
    }
  },
  watch: {
    sectionFilter: {
      async handler () {
        this.resetEventSaleProducts()
        await this.getEventSaleSectionProducts()
      },
      deep: true
    },
    search () {
      this.showResultText = false
      this.debounceSearch()
    }
  },
  async mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.getEventSaleSectionProducts()
  },
  methods: {
    ...mapActions('Contents', [
      'getEventSaleSection',
      'getEventSaleOtherSection'
    ]),
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },
    resetEventSaleProducts () {
      this.eventSaleProducts = {
        isLoading: false,
        page: 1,
        perPage: 10,
        totalPage: 0,
        total: 0,
        data: []
      }
    },
    handleInfiniteLoading () {
      this.getEventSaleSectionProducts()
    },
    debounceSearch: _.debounce(async function () {
      this.resetEventSaleProducts()
      await this.getEventSaleSectionProducts()
    }, 750),
    async getEventSaleSectionProducts () {
      this.eventSaleProducts.isLoading = true

      const wherePayload = {}
      if (this.eventSaleSessionId) {
        wherePayload.event_sale_session_id = this.eventSaleSessionId
      }

      try {
        let resp

        if (this.eventSaleSectionId) {
          const payload = {
            id: this.eventSaleSectionId,
            payload: {
              data: {
                page: this.eventSaleProducts.page,
                per_page: this.eventSaleProducts.perPage,
                where: wherePayload
              }
            }
          }

          if (isObjectNotEmptyOrNull(this.sectionFilter)) {
            payload.payload.data = {
              ...payload.payload.data,
              order_by: {
                [this.sectionFilter.value]: this.sectionFilter.sorting
              }
            }
          }

          if (this.search) {
            payload.payload.data = {
              ...payload.payload.data,
              search: this.search
            }
          }

          resp = await this.getEventSaleSection(payload)
          this.showResultText = true
          this.$emit('fetched-section-sale-data', resp.data.data.event_sale_section)
        } else {
          resp = await this.getEventSaleOtherSection({
            data: {
              page: this.eventSaleProducts.page,
              per_page: this.eventSaleProducts.perPage,
              where: wherePayload
            }
          })
        }

        if (resp.data.data.event_sale_section) {
          this.eventSaleProducts.data = [
            ...this.eventSaleProducts.data,
            ...resp.data.data.event_sale_section.event_sale_products.data
          ]
          this.eventSaleProducts.totalPage = resp.data.data.event_sale_section.event_sale_products.last_page
          this.eventSaleProducts.total = resp.data.data.event_sale_section.event_sale_products.total

          this.eventSaleSection = resp.data.data.event_sale_section
          delete this.eventSaleSection.event_sale_products
        }
        this.eventSaleProducts.page++
      } catch (e) {
        this.$message.error('Gagal mendapatkan data sale section produk')
      }

      this.eventSaleProducts.isLoading = false
    }
  }
}
