import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=e8ae2602&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=e8ae2602&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8ae2602",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,IconSearch: require('/usr/src/app/components/icons/IconSearch.vue').default,SearchSuggestion: require('/usr/src/app/components/home/base/SearchSuggestion.vue').default,NavbarCart: require('/usr/src/app/components/home/base/NavbarCart.vue').default,NavbarNotif: require('/usr/src/app/components/home/base/NavbarNotif.vue').default,IconChevronRight: require('/usr/src/app/components/icons/IconChevronRight.vue').default,IconPanintiCoin: require('/usr/src/app/components/icons/IconPanintiCoin.vue').default,IconLogout: require('/usr/src/app/components/icons/IconLogout.vue').default,MegamenuCategory: require('/usr/src/app/components/home/base/MegamenuCategory.vue').default})
