import { render, staticRenderFns } from "./CardPartner.vue?vue&type=template&id=1dec38c1&scoped=true&"
import script from "./CardPartner.vue?vue&type=script&lang=js&"
export * from "./CardPartner.vue?vue&type=script&lang=js&"
import style0 from "./CardPartner.vue?vue&type=style&index=0&id=1dec38c1&lang=scss&scoped=true&"
import style1 from "./CardPartner.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dec38c1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Badge: require('/usr/src/app/components/base/Badge.vue').default,Clamp: require('/usr/src/app/components/base/Clamp.vue').default})
