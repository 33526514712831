import {
  getResponseErrorMessage,
  getResponseErrorStatus,
  isResponseInternalServerError
} from '@/utils/helpers/response-helper'

export default {
  computed: {
    isHomePage () {
      return this.$route.path === '/'
    },

    isAccountPage () {
      return this.$route.path.includes('/account')
    }
  },

  methods: {
    setPageToErrorPage (error, defaultMessage = '') {
      this.$nuxt.error({
        statusCode: getResponseErrorStatus(error),
        message: !isResponseInternalServerError(error) ? getResponseErrorMessage(error) : defaultMessage
      })
    }
  }
}
