//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  watchQuery: true,

  beforeRouteLeave (to, from, next) {
    if (to.name !== from.name) {
      this.isLeave = true
    }
    next()
  },
  data () {
    return {
      isLeave: false,

      breadCrumb: [
        {
          path: '/'
        },
        {
          name: this.$route.query.type === 'brand' ? 'Brand' : 'Toko'
        }
      ]
    }
  },

  head () {
    return {
      title: `${
        this.$route.query.type === 'brand' ? 'Brand' : 'Store'
      } Partner | Paninti Store`
    }
  }
}
