var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-cart"},[_c('div',{staticClass:"paninti-h4 text-start color-text-paragraph paninti-py-8"},[_vm._v("\n    Keranjang\n  ")]),_vm._v(" "),_c('ul',{staticClass:"navcart-list"},_vm._l((_vm.listCart),function(itemCart,index){return _c('li',{key:itemCart.product + '-' + index,staticClass:"d-flex ml-0 mr-0"},[_c('div',{staticClass:"paninti-mr-8 text-center"},[_c('a',{staticClass:"navcart-img-item d-inline-block",attrs:{"href":itemCart.product.slug ? ("/product/" + (itemCart.product.slug)) : '#'}},[_c('img',{staticClass:"img-cartItem rounded",attrs:{"src":_vm.getDefaultImageFromFiles(
                itemCart.product.files ? itemCart.product.files : [],
                'image'
              ),"alt":"#"},on:{"error":_vm.getDefaultImgProductOnError}})])]),_vm._v(" "),_c('div',{staticClass:"text-left w-100"},[_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('div',{staticClass:"paninti-content-xs bold color-text-title"},[_vm._v("\n              "+_vm._s(itemCart.product.brand_name)+"\n            ")]),_vm._v(" "),_c('a',{staticClass:"paninti-content-sm color-text-paragraph",attrs:{"href":itemCart.product.slug
                  ? ("/product/" + (itemCart.product.slug))
                  : '#'}},[_c('client-only',[_c('v-clamp',{attrs:{"autoresize":"","max-lines":2}},[_vm._v("\n                  "+_vm._s(itemCart.product.name)+" -\n                  "+_vm._s(((itemCart.quantity) + " produk"))+"\n                ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"paninti-mb-2"}),_vm._v(" "),(itemCart.product.variant_name != 'Default')?_c('div',{staticClass:"paninti-content-xs color-text-sub paninti-mb-2"},[_vm._v("\n              "+_vm._s(itemCart.product.variant_name)+" -\n              "+_vm._s(itemCart.quantity)+" produk\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"quantity paninti-mb-2"},[_c('span',{staticClass:"paninti-content-sm semibold color-text-paragraph"},[_vm._v(_vm._s(itemCart.product.discounted_price ?
                  _vm.formatRupiah(
                    itemCart.product.discounted_price.toString(),
                    'Rp'
                  ) : ''))]),_vm._v(" "),(itemCart.product.discounted_price !== itemCart.product.price)?_c('span',{staticClass:"paninti-content-xs striketought color-text-sub paninti-ml-4"},[_vm._v(_vm._s(itemCart.product.price ?
                  _vm.formatRupiah(
                    itemCart.product.price.toString(),
                    'Rp'
                  ) : ''))]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"remove",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.handleDeleteCart(itemCart.id)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])])])])}),0),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('Divider',{staticClass:"paninti-my-12"}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"paninti-content-xs color-text-paragraph"},[(_vm.qtyCart > 3)?_c('span',[_vm._v("+"+_vm._s(_vm.qtyCart - 3 > 99 ? 99 : _vm.qtyCart - 3)+" Produk lainnya")]):_vm._e()]),_vm._v(" "),_vm._m(0)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"paninti-btn paninti-btn-primary paninti-btn-small w-100",attrs:{"href":"/cart"}},[_vm._v("Tampilkan Semua Keranjang")])])}]

export { render, staticRenderFns }