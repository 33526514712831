export default {
  data () {
    return {
      //
    }
  },

  computed: {
    isUserLoggedIn () {
      return this.$auth.loggedIn
    },

    userLoggedIn () {
      return this.$auth.user || null
    }
  },

  mounted () {
    //
  },

  methods: {
    //
  }
}
