var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-partner-related-content-container"},[(_vm.partner.id)?_c('div',{staticClass:"card-partner-related paninti-row-gap-12"},[_c('div',{staticClass:"card-partner-related-info-container col-12 col-md-5 d-flex paninti-column-gap-12"},[_c('div',{staticClass:"card-partner-related-logo-container"},[_c('img',{staticClass:"card-partner-related-logo",attrs:{"src":_vm.getThumbnailImage(
                _vm.partner.files,
                _vm.storeSimpleType[_vm.partner.type] || _vm.storeSimpleType.store
              ),"alt":"Partner Logo"},on:{"error":function () {
                              var args = [], len = arguments.length;
                              while ( len-- ) args[ len ] = arguments[ len ];

                              return _vm.getDefaultImgProfile.apply(
                  void 0, args.concat( [_vm.storeSimpleType[_vm.partner.type] || _vm.storeSimpleType.store] )
                );
}}})]),_vm._v(" "),_c('div',{staticClass:"d-flex paninti-row-gap-4 flex-column"},[_c('Badge',{staticClass:"badge-partner",class:_vm.isBrandPartner ? 'badge-brand' : 'badge-store',attrs:{"text":_vm.isBrandPartner ? _vm.storeTypes.brand.label : _vm.storeTypes.store.label,"type":_vm.isBrandPartner
                ? _vm.storeSimpleType.brand_partner
                : _vm.storeSimpleType.store_partner,"icon":""}}),_vm._v(" "),_c('div',{staticClass:"paninti-content-base color-text-paragraph font-weight-semibold"},[_vm._v("\n            "+_vm._s(_vm.partner.name)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"d-flex paninti-column-gap-6 align-items-center"},[_c('IconPinLocation',{attrs:{"icon-class":"paninti-fill-text-sub"}}),_vm._v(" "),_c('div',{staticClass:"paninti-content-sm color-text-sub"},[_vm._v("\n              "+_vm._s(_vm.partner.main_branch_city || 'Indonesia')+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"paninti-content-sm border-end"},[_vm._v("\n \n            ")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('IconStar',{staticClass:"paninti-mr-2 my-auto"}),_vm._v(" "),_c('div',{staticClass:"paninti-content-base color-text-paragraph font-weight-semibold"},[_vm._v("\n                "+_vm._s(_vm.partner.rating)+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"paninti-content-sm color-text-title-alt"},[_vm._v("\n              ("+_vm._s(_vm.partner.total_review || 0)+" Ulasan)\n            ")])],1)],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex col-12 col-md-7 justify-content-end flex-wrap paninti-row-gap-12 paninti-column-gap-20"},[_c('div',{staticClass:"card-partner-related-product-sold-container col-12 col-md-7 d-flex justify-content-between justify-content-md-end paninti-column-gap-20"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"paninti-content-sm color-text-paragraph text-center text-md-end"},[_vm._v("\n              "+_vm._s(_vm.isBrandPartner ? 'Store Partner' : 'Total Cabang')+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"paninti-content-base color-text-paragraph font-weight-semibold text-center text-md-end"},[_vm._v("\n              "+_vm._s(_vm.isBrandPartner
                  ? _vm.partner.total_partner
                  : _vm.partner.total_branch || 0)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"border-start"}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"paninti-content-sm color-text-paragraph text-center text-md-end"},[_vm._v("\n              Total Produk\n            ")]),_vm._v(" "),_c('div',{staticClass:"paninti-content-base color-text-paragraph font-weight-semibold text-center text-md-end"},[_vm._v("\n              "+_vm._s(_vm.partner.total_product || 0)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"border-start"}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"paninti-content-sm color-text-paragraph text-center text-md-end"},[_vm._v("\n              Produk terjual\n            ")]),_vm._v(" "),_c('div',{staticClass:"paninti-content-base color-text-paragraph font-weight-semibold text-center text-md-end"},[_vm._v("\n              "+_vm._s(_vm.partner.total_sold)+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"card-partner-related-action-container col-12 col-md-3"},[_c('button',{staticClass:"paninti-btn paninti-btn-primary-outline ms-auto paninti-mr-12 w-100 justify-content-center",on:{"click":function($event){return _vm.onCardPartnerRelatedActionClicked(_vm.partner.slug)}}},[_vm._v("\n            Kunjungi Partner\n          ")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }