import Voucher from '@/services/Voucher/'
import mixin from '@/mixins'
export const state = () => ({
  vouchers: [],
  vouchersDiscount: {}, // { paginated, nextPage, total, data }
  vouchersShipping: {}, // { paginated, nextPage, total, data }
  voucher: {},
  voucherUsed: [],
  showDetail: false,
  checkVoucher: false,
  pageVoucher: 1,
  totalPageVoucher: 1
})

export const mutations = {
  setVouchers (state, payload) {
    if (Number(payload.page) === 1) {
      state.vouchers = payload.data
    } else {
      state.vouchers = state.vouchers.concat(payload.data)
    }
    state.pageVoucher++
  },
  setVouchersByType (state, payload) {
    const { paginated, type, nextPage, total } = payload
    let { data } = payload

    data = data.map((dt) => {
      const usedIdx = state.voucherUsed.findIndex((used) => {
        return used.id === dt.id
      })
      let dataObj = {
        ...dt,
        active: true,
        selectType: 'choose'
      }

      if (usedIdx > -1) {
        dataObj = Object.assign(dataObj, {
          available: state.voucherUsed[usedIdx].available,
          total_discount: state.voucherUsed[usedIdx].total_discount,
          selectType: state.voucherUsed[usedIdx].selectType,
          minimum_amount: state.voucherUsed[usedIdx].minimum_amount,
          date_start: state.voucherUsed[usedIdx].date_start,
          date_end: state.voucherUsed[usedIdx].date_end
        })
      }
      return dataObj
    })

    payload.data = data
    if (paginated) {
      if (!state[type].data) {
        delete payload.type
        state[type] = payload
      } else {
        const oldData = state[type].data

        const newData = data
        oldData.forEach((old) => {
          const newIdx = newData.findIndex((newDt) => {
            return old.id === newDt.id
          })

          if (newIdx > -1) {
            newData.splice(newIdx, 1)
          }
        })
        state[type].data = oldData.concat(newData)
        state[type].nextPage = nextPage
        state[type].total = total
      }
    } else {
      delete payload.type
      state[type] = payload
    }
  },
  editVouchersByType (state, payload) {
    const { voucherId, type, additionalData } = payload

    if (state[type] && state[type].data && state[type].data.length) {
      const voucherIdx = state[type].data.findIndex((voucher) => {
        return voucher.id === voucherId
      })

      if (voucherIdx > -1) {
        const oldData = state[type].data[voucherIdx]
        const mergedData = {
          ...oldData,
          ...additionalData
        }

        state[type].data[voucherIdx] = JSON.parse(JSON.stringify(mergedData))
      }
    }
  },
  resetVouchersByType (state, payload) {
    const stateVoucherType = payload && payload.length ? payload : ['vouchersDiscount', 'vouchersShipping']

    stateVoucherType.forEach((stateData) => {
      if (state[stateData] && state[stateData].data) {
        const resetData = state[stateData].data.map((dt) => {
          const deletedObj = ['available', 'total_discount']

          deletedObj.forEach((obj) => {
            delete dt[obj]
          })
          return {
            ...dt,
            selectType: 'choose'
          }
        })

        state[stateData].data = resetData
      }
    })
  },
  addVoucherAfterCode (state, payload) {
    const { voucher, type } = payload

    if (state[type].data) {
      const voucherExistIdx = state[type].data.findIndex((vc) => {
        return vc.id === voucher.id
      })

      if (voucherExistIdx > -1) {
        state[type].data[voucherExistIdx] = {
          ...voucher
        }
      } else {
        state[type].data.unshift(voucher)
      }
    } else {
      state[type].data = [voucher]
    }
  },
  clearVouchersByType (state, payload) {
    state[payload] = {}
  },
  setVoucher (state, payload) {
    state.voucher = payload
  },
  setVoucherUsed (state, payload) {
    state.voucherUsed = payload
  },
  setToggleDetail (state, payload) {
    state.showDetail = payload
  },
  setPage (state, payload) {
    state.pageVoucher = payload
  },
  setTotalPage (state, payload) {
    state.totalPageVoucher = payload
  },
  setOnCheck (state, payload) {
    state.checkVoucher = payload
  }
}

export const getters = {
  listVouchers (state) {
    const list = []

    if (Object.keys(state.vouchersShipping).length && state.vouchersShipping.data.length !== 0) {
      list.push({
        ...state.vouchersShipping,
        label: 'Voucher Potongan Ongkos Kirim',
        slug: 'free-shipping-cost',
        type: 'free_shipping_cost'
      })
    }

    if (Object.keys(state.vouchersDiscount).length && state.vouchersDiscount.data.length !== 0) {
      list.push({
        ...state.vouchersDiscount,
        label: 'Voucher Diskon Produk',
        slug: 'discount',
        type: 'discount'
      })
    }

    return list
  },
  voucherUsedId (state) {
    if (state.voucherUsed && state.voucherUsed.length) {
      return state.voucherUsed.map((used) => {
        return used.id
      })
    }
    return []
  }
}

export const actions = {
  getVouchers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getVouchers(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setVouchers', {
              data: response.data.data.vouchers.data,
              page: response.data.data.vouchers.current_page
            })
            commit('setTotalPage', response.data.data.vouchers.last_page)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getVouchersByType ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getVouchersByType(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      ).then((response) => {
        const res = response.data.data.vouchers
        const paginated = payload.data.paginated
        const type = payload.type === 'discount' ? 'vouchersDiscount' : 'vouchersShipping'

        let comPayload = {
          paginated,
          type
        }

        if (paginated) {
          comPayload = Object.assign(comPayload, {
            nextPage: mixin.methods.getPageFromUrl(res.next_page_url),
            total: res.total,
            data: res.data
          })
        } else {
          comPayload = Object.assign(comPayload, {
            nextPage: null,
            total: 1,
            data: res
          })
        }

        commit('setVouchersByType', comPayload)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  checkVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.checkVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  showVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.showVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setVoucher', response.data.data.voucher)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getUserVoucher ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getUserVoucher(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getUserVoucherSummary ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Voucher.getUserVoucherSummary(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}
