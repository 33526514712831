//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { defaultPaginationAttributes, assignResponseToDataModelData, resetPaginationData } from '@/utils/helpers/pagination-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
export default {
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: 'Semua',
          value: 'all',
          count: 0
        },
        {
          id: 2,
          label: 'Paninti',
          value: 'paninti',
          count: 0
        },
        {
          id: 3,
          label: 'Brand',
          value: 'brand_partner',
          count: 0
        },
        {
          id: 4,
          label: 'Toko',
          value: 'store_partner',
          count: 0
        }
      ],
      voucherData: {
        ...defaultPaginationAttributes()
      },
      infiniteId: +new Date(),
      voucherableNotFromPaninti: {}
    }
  },
  watch: {
    '$route.query': {
      async handler () {
        resetPaginationData(this.voucherData)
        await this.fetchUserVoucher()
        this.infiniteId++
      },
      deep: true
    }
  },
  async mounted () {
    const getApis = [
      this.fetchUserVoucherSummary(),
      this.fetchUserVoucher()
    ]

    await Promise.all(getApis)
  },
  methods: {
    isArrayNotNullOrEmpty,
    ...mapActions('Voucher', ['getUserVoucher', 'getUserVoucherSummary', 'showVoucher']),
    clickVoucherFilter (tab) {
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: tab.value
        }
      })
    },
    async fetchUserVoucher () {
      try {
        this.voucherData.loading = true
        const resp = await this.getUserVoucher({
          params: {
            paginated: true,
            page: this.voucherData.page + 1 || 1,
            per_page: 10,
            tab: !this.$route.query.tab ? 'all' : this.$route.query.tab
          }
        })

        assignResponseToDataModelData(this.voucherData, resp.data.data.vouchers, false)
        if (this.$refs.InfiniteLoading) {
          if (this.voucherData.page < this.voucherData.totalPage) {
            this.$refs.InfiniteLoading.stateChanger.loaded()
          } else {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.voucherData.loading = false
      }
    },
    async fetchUserVoucherSummary () {
      try {
        const resp = await this.getUserVoucherSummary()
        const summary = resp.data.data.vouchers

        Object.entries(summary).forEach(([key, value]) => {
          const indexTab = this.tabs.findIndex((tab) => {
            return tab.value === key
          })

          if (indexTab > -1) {
            this.tabs[indexTab].count = value
          }
        })
      } catch (error) {
        console.log(error)
      } finally {

      }
    },
    handleInfiniteLoading () {
      this.fetchUserVoucher()
    },
    async fetchDetailVoucher (voucher) {
      try {
        if (voucher.voucher_from !== 'paninti') {
          this.voucherableNotFromPaninti = voucher.voucherable
        }
        await this.showVoucher({ voucherId: voucher.id })
        this.$refs.btnOpenDetailVoucher.click()
      } catch (error) {
        console.log(error)
      }
    },
    handleModalClose () {
      this.voucherableNotFromPaninti = {}
    }
  }
}
