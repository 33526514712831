//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState } from 'vuex'
import { storeTypes, optOrderBy } from '@/utils/helpers/store-helper'
export default {
  mixins: [mixins],

  data () {
    return {
      catalogListPartner: {
        isLoading: true,
        perPage: 10,
        page: 1,
        totalPage: 0,
        total: 0,
        data: []
      },
      orderBy: ''
    }
  },

  computed: {
    ...mapState('User/address', ['location']),

    isRouteNearbyPartner () {
      return this.$route.query.order_by === 'nearest'
    },

    type () {
      return this.$route.query.type || 'store'
    },
    storeTypes () {
      return storeTypes
    },
    optOrderBy () {
      return optOrderBy
    }
  },

  watch: {
    location (newVal, oldVal) {
      if (!oldVal.city_id) {
        return
      }

      this.catalogListPartner.data = []
      this.catalogListPartner.page = 1
      this.catalogListPartner.totalPage = 0
      this.catalogListPartner.total = 0

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },

    '$route.query' (newVal, oldVal) {
      this.orderBy = this.$route.query.order_by || ''

      this.catalogListPartner.data = []
      this.catalogListPartner.totalPage = 0
      this.catalogListPartner.page = 1
      this.catalogListPartner.total = 0

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  },
  mounted () {
    this.orderBy = this.$route.query.order_by || ''
  },
  methods: {
    handleInfiniteLoading () {
      this.handleGetPartners()
    },

    async handleGetPartners () {
      await this.getPartners()
    },
    handleChangeOrderBy () {
      this.$router.push({
        query: {
          ...this.$route.query,
          order_by: this.orderBy
        }
      })
    },
    async getPartners () {
      this.catalogListPartner.isLoading = true

      const cityId = localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location')).city_id
        : 155

      try {
        const resp = await this.$store.dispatch('Partner/getNearestPartners', {
          params: {
            city_id: this.isRouteNearbyPartner ? cityId : undefined,
            type: this.storeTypes[this.type].value || storeTypes.store.value,
            per_page: this.catalogListPartner.perPage,
            page: this.catalogListPartner.page,
            paginated: true,
            order_by: this.orderBy || undefined
          }
        })

        this.catalogListPartner.data = this.catalogListPartner.data.concat(
          resp.data.data.stores.data
        )

        this.catalogListPartner.totalPage = resp.data.data.stores.last_page
        this.catalogListPartner.total = resp.data.data.stores.total
        this.catalogListPartner.page++

        if (this.catalogListPartner.page > this.catalogListPartner.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) &&
            this.$refs.InfiniteLoading.length
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (
          Array.isArray(this.$refs.InfiniteLoading) &&
          this.$refs.InfiniteLoading.length
        ) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (err) {
        this.$message.error('Gagal mendapatkan data partner')
      }

      this.catalogListPartner.isLoading = false
    }
  }
}
