var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"paninti-tab",class:{
    'paninti-tab-large': _vm.size === 'large',
    'paninti-tab-medium': _vm.size === 'medium',
    'paninti-tab-small': _vm.size === 'small',

    'paninti-tab-default': _vm.variant === _vm.tabVariant.default,
    'paninti-tab-default-rounded': _vm.variant === _vm.tabVariant.defaultRounded,
    'paninti-tab-default-with-border':
      _vm.variant === _vm.tabVariant.defaultWithBorder,
    'paninti-tab-default-right-line': _vm.variant === _vm.tabVariant.defaultRightLine,

    'paninti-tab-selected-bottom-line':
      _vm.variant === _vm.tabVariant.selectedBottomLine,
    'paninti-tab-selected-right-line':
      _vm.variant === _vm.tabVariant.selectedRightLine,

    'paninti-tab-filled color-text-title-alt': _vm.variant === _vm.tabVariant.filled,
    'paninti-tab-filled-rounded color-text-title-alt':
      _vm.variant === _vm.tabVariant.filledRounded
  },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v("\n  "+_vm._s(_vm.label)+"\n\n  "),(_vm.count !== null)?_c('span',{staticClass:"paninti-tab-count"},[_vm._v("\n    "+_vm._s(_vm.count)+"\n  ")]):_vm._e(),_vm._v(" "),_vm._t("suffix-icon")],2)}
var staticRenderFns = []

export { render, staticRenderFns }