var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-modal-detail-withdrawal-commission-type-in"},[_c('div',{staticClass:"modal-body-section"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"section-content-container"},[_c('div',{staticClass:"section-content-item"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"section-content-item-content"},[_c('div',{staticClass:"paninti-content-base regular color-text-paragraph paninti-mb-0"},[_vm._v("\n            "+_vm._s(_vm.affiliateCommissionOrder
                ? _vm.affiliateCommissionOrder.reference
                : '')+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"section-content-item"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"section-content-item-content"},[_c('div',{staticClass:"paninti-content-base regular color-text-paragraph paninti-mb-0"},[_vm._v("\n            "+_vm._s(_vm.formatRupiah(
                _vm.affiliateCommissionOrder
                  ? _vm.affiliateCommissionOrder.total_payment
                  : 0,
                'Rp'
              ))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"section-content-item"},[_vm._m(3),_vm._v(" "),_c('div',{staticClass:"section-content-item-content"},[_c('h4',{staticClass:"paninti-h4 color-text-link-primary paninti-mb-0"},[_vm._v("\n            "+_vm._s(_vm.formatRupiah(
                _vm.affiliateCommissionOrder
                  ? _vm.affiliateCommissionOrder.affiliate_commission_earned
                  : 0,
                'Rp'
              ))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"section-content-item"},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"section-content-item-content"},[_c('div',{staticClass:"paninti-content-base regular color-text-paragraph paninti-mb-0"},[_vm._v("\n            "+_vm._s(_vm.commissionHistoryCreatedAt
                ? _vm.formatDateWithTimezone(_vm.commissionHistoryCreatedAt, {
                  formatResult: 'DD MMMM YYYY HH:mm'
                })
                : '-')+"\n          ")])])])])]),_vm._v(" "),(
      _vm.affiliateCommissionOrder && _vm.affiliateCommissionOrder.products.length > 0
    )?[_c('Divider',{attrs:{"custom-class":"paninti-my-spacing-m"}}),_vm._v(" "),_c('div',{staticClass:"modal-body-section"},[_c('div',{staticClass:"section-content-container"},[_c('el-collapse',{staticClass:"list-commission-products-collapse",model:{value:(_vm.listCommissionProductsCollapse),callback:function ($$v) {_vm.listCommissionProductsCollapse=$$v},expression:"listCommissionProductsCollapse"}},[_c('el-collapse-item',{attrs:{"title":"Produk","name":"1"}},[_c('div',{staticClass:"collapse-header"},[_c('div',{staticClass:"collapse-header-left"},[_c('span',{staticClass:"paninti-content-base semibold color-text-title"},[_vm._v("\n                  Informasi Produk\n                ")])]),_vm._v(" "),_c('div',{staticClass:"collapse-header-right"},[_c('span',{staticClass:"paninti-content-base semibold color-text-title"},[_vm._v("\n                  Nilai Komisi\n                ")])])]),_vm._v(" "),_vm._l((_vm.affiliateCommissionOrder.products),function(product,index){return _c('div',{key:_vm.generateRandomString(4) + '-' + index,staticClass:"collapse-content"},[_c('div',{staticClass:"collapse-content-left"},[_c('div',{staticClass:"d-flex wrapper-product"},[_c('img',{staticClass:"img-product",attrs:{"src":_vm.getThumbnailImage(product.files, 'image'),"alt":"Product Image"},on:{"error":_vm.getDefaultImgProduct}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[_c('v-clamp',{staticClass:"paninti-content-base regular color-text-paragraph paninti-mb-spacing-xxs",attrs:{"autoresize":"","max-lines":1}},[_vm._v("\n                      "+_vm._s(product.product_name)+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"paninti-content-sm regular color-text-paragraph-alt"},[_vm._v("\n                      "+_vm._s(product.detail_name !== _vm.variantNames.default.name
                          ? product.detail_name
                          : '')+"\n                      x "+_vm._s(product.quantity)+"\n                    ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"collapse-content-right"},[_c('span',{staticClass:"paninti-content-base regular color-text-paragraph"},[_vm._v("\n                  "+_vm._s(_vm.formatRupiah(
                      product.affiliate_commission_earned || 0,
                      'Rp'
                    ))+"\n                ")])])])})],2)],1)],1)])]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-title-container"},[_c('h4',{staticClass:"paninti-h4 color-text-paragraph paninti-mb-0"},[_vm._v("\n        Informasi Saldo\n      ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-content-item-title"},[_c('label',{staticClass:"paninti-content-base semibold color-text-title"},[_vm._v("\n            No. Pesanan\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-content-item-title"},[_c('label',{staticClass:"paninti-content-base semibold color-text-title"},[_vm._v("\n            Total Pesanan\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-content-item-title"},[_c('label',{staticClass:"paninti-content-base semibold color-text-title"},[_vm._v("\n            Nilai Komisi\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-content-item-title"},[_c('label',{staticClass:"paninti-content-base semibold color-text-title"},[_vm._v("\n            Tanggal Masuk\n          ")])])}]

export { render, staticRenderFns }