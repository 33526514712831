//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import { formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
import { affiliateBalanceWithdrawalStatuses } from '@/utils/helpers/affiliate-balance-withdrawal-helper'

export default {
  props: {
    type: {
      type: String,
      default: 'commissionHistory'
    },

    affiliateCommissionHistory: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    affiliateBalanceWithdrawalStatuses () {
      return affiliateBalanceWithdrawalStatuses
    },

    affiliateBalanceWithdrawal () {
      return this.affiliateCommissionHistory
        ? this.affiliateCommissionHistory.affiliate_balance_withdrawal
        : null
    },

    commissionHistoryCreatedAt () {
      return this.affiliateBalanceWithdrawal
        ? this.affiliateBalanceWithdrawal.created_at
        : null
    }
  },

  methods: { formatDateWithTimezone, formatRupiah }
}
