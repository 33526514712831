//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'
import { formatRupiah } from '@/utils/helpers/string-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'

export default {
  props: {
    filterModel: {
      type: Object,
      required: true
    },

    filterData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes(),
        data: {
          category: [],
          location: [],
          price: {
            from: 0,
            until: 5000000
          },
          rating: [],
          variant: {}
        }
      })
    },

    filterLabel: {
      type: Object,
      default: () => ({
        category: 'Kategori'
      })
    }
  },

  data () {
    return {
      //
    }
  },

  watch: {
    filterData: {
      handler (value) {
        if (value) {
          if (Number(this.filterModel.price[0]) < this.filterData.data.price.from) {
            this.filterModel.price[0] = this.filterData.data.price.from
          }

          if (Number(this.filterModel.price[1]) > this.filterData.data.price.until) {
            this.filterModel.price[1] = this.filterData.data.price.until
          }
        }
      },
      deep: true
    }
  },

  methods: {
    formatRupiah,

    getNodeTreeClassName (treeNodeData) {
      if (this.isCategoryTreeItemActive(treeNodeData)) {
        return 'tree-node-active'
      }

      return (treeNodeData.level === 1 ? 'color-text-paragraph ' : 'color-text-sub ')
    },

    isCategoryTreeItemActive (item) {
      return this.filterModel.activeCategoryTreeItems.includes(item.slug)
    },

    handleFilterCategoryTreeNodeOnClick (data) {
      this.$emit('filter-category-tree-node-on-click', data)
    },

    handleFilterLocationCheckboxGroupOnChange (data) {
      this.$emit('filter-location-checkbox-group-on-change', data)
    },

    handleFilterPriceSliderOnChange (data) {
      this.$emit('filter-price-slider-on-change', data)
    },

    handleFilterRatingCheckboxGroupOnChange (data) {
      this.$emit('filter-rating-checkbox-group-on-change', data)
    },

    resetRouteQueryFilter () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          saleProductsCategories: null,
          saleProductsLocations: null,
          saleProductsPriceRange: null,
          saleProductsRating: null
        })
      })
    }
  }
}
