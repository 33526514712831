import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getParentCategories (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/categories/parent-homepage',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductCategoriesTree (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/api/v1/product-categories/tree',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getSimpleCategory (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/api/v1/product-categories',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCategories (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/categories',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCategory (auth, payload) {
    return apiClient({
      method: 'get',
      url: 'v1/categories/page/' + payload,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getMetaCategory (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/categories/' + payload + '/meta-scripts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCategoriesForNavbar (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/categories/navbar',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
