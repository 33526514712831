//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'

import panstoreMixin from '@/mixins/index'
import Pusher from 'pusher-js'
import { StatusCodes } from 'http-status-codes'
import { pusherChannels } from '@/utils/helpers/pusher-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { storeTypes } from '@/utils/helpers/store-helper'

export default {
  mixins: [panstoreMixin],

  async asyncData ({ store, params, error, app }) {
    store.commit('Product/setSelectedDetails', {})

    let product = {}
    try {
      const cityId = store.state.User.address.location.city_id || 151
      const resGetProduct = await store.dispatch('Product/getDetailProduct', {
        slug: params.slug,
        params: {
          refactor: true,
          session_id: app.$cookies.get('session_id'),
          city_id: cityId
        }
      })

      product = resGetProduct.data.data.product
    } catch (err) {
      console.log(error)
      console.log('HERE GET PRODUCT')
      if (err.response && err.response.status === 404) {
        error({
          statusCode: 404,
          image: require('@/static/img/error/empty-cart.svg'),
          message: 'Produk tidak ditemukan',
          subMessage: 'Produk yang dimaksud tidak ada pada data kami. Mohon untuk cari dengan kata kunci yang sesuai',
          type: 'product-not-found'
        })
      } else {
        error({
          statusCode: err.response ? err.response.status : 500
        })
      }
    }

    let metaData = {}
    try {
      const respMetaData = await store.dispatch(
        'Product/getMetaProduct',
        params.slug
      )
      if (respMetaData.status === 200) {
        if (respMetaData.data.data.meta_scripts) {
          metaData = respMetaData.data.data.meta_scripts
        }
      }
    } catch (error) {
      console.log(error)
    }

    store.commit('Product/ProductDetailPage/setProduct', product)

    return {
      metaData,
      data: product || {},
      breadCrumb: [
        {
          path: '/'
        },
        {
          name: product && product.brand ? product.brand.name : '-',
          path:
            product && product.brand
              ? `/partner/${product.brand.slug}`
              : '#'
        },
        {
          name: product ? product.name : '-'
        }
      ]
    }
  },

  data () {
    return {
      showBrand: false
    }
  },

  computed: {
    ...mapState('Product', ['product', 'fulfilledProduct']),
    ...mapState('User/address', ['location']),
    storeTypes () {
      return storeTypes
    },
    userLoggedIn () {
      return this.$auth.user
    },

    productSEOImage () {
      let urlProductSEOImg = process.env.URL + '/default-og-image.jpg'

      if (Array.isArray(this.metaData)) {
        const metaScriptProduct = this.metaData.filter((script) => {
          return script.json && script.json['@type'] === 'Product'
        })

        if (metaScriptProduct.length > 0) {
          urlProductSEOImg = metaScriptProduct[0].json.image
        }
      }

      return urlProductSEOImg
    },

    productMetaKeyword () {
      let productMetaKeyword = ''

      if (this.data && Array.isArray(this.data.meta_keywords)) {
        if (this.data.meta_keywords.length === 1 && this.data.meta_keywords[0].trim() !== '') {
          productMetaKeyword = this.data.meta_keywords.map(keyword => keyword.trim()).join(',')
        } else {
          const productNameArray = this.data.name.trim().split(' ')

          for (let i = 1; i < productNameArray.length; i++) {
            for (let j = 0; j <= i; j++) {
              productMetaKeyword += productNameArray[j]
              if (j < i) { productMetaKeyword += ' ' }
            }

            if (i < productNameArray.length - 1) { productMetaKeyword += ',' }
          }
        }
      }

      return productMetaKeyword.toLowerCase()
    }
  },
  watch: {
    async location () {
      await this.fetchProduct()
    }
  },

  async mounted () {
    await this.handleIncreaseAffiliate()

    this.initTempProductState()
    this.initPusherListenerCreateAffiliateProduct()
  },

  methods: {
    isObjectNotEmptyOrNull,
    ...mapMutations('Product', ['setSelectedDetails']),
    ...mapMutations('Product/ProductDetailPage', [
      'setIsProductWishlisted', 'setTotalProductWishlisted',
      'setIsProductAffiliated'
    ]),

    ...mapActions('User', ['toggleWishlist']),
    ...mapActions('Product', ['addProductAffiliate', 'getDetailProduct']),
    ...mapActions('Affiliate', ['increaseClickAffiliateProduct']),

    initTempProductState () {
      this.setIsProductWishlisted(this.data.is_wishlisted || false)
      this.setTotalProductWishlisted(this.data.total_wishlist || 0)
      this.setIsProductAffiliated(this.data.is_affiliated || false)
    },

    initPusherListenerCreateAffiliateProduct () {
      if (!this.userLoggedIn) { return }

      const pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: 'ap1',
        forceTLS: true,
        authEndpoint: `${process.env.BASE_API}/v1/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `${this.$auth.getToken('local')}`
          }
        }
      })

      pusher.subscribe(`private-user.${this.userLoggedIn.id}.` + pusherChannels.createAffiliateProduct.name)
      pusher.bind(pusherChannels.createAffiliateProduct.events.affiliateProductCreated.name, (data) => {
        this.$Swal.close()

        if (data.status_code === StatusCodes.OK || data.status_code === StatusCodes.CREATED) {
          this.handleAddProductToAffiliateSuccess()
        } else {
          this.handleAddProductToAffiliateFailed()
        }
      })
    },

    async fetchProduct () {
      try {
        const resp = await this.getDetailProduct({
          slug: this.$route.params.slug,
          params: {
            refactor: true,
            session_id: this.$cookies.get('session_id'),
            city_id: this.location.city_id || 151
          }
        })

        this.data = resp.data.data.product
      } catch (error) {
        console.log(error)
      }
    },

    handleShowBrand (value) {
      this.showBrand = value
    },

    getVideos (files) {
      return files.filter((file) => {
        return file.type === 'youtube_video'
      })
    },

    async handleIncreaseAffiliate () {
      if (this.$route.query && this.$route.query.affiliate_code) {
        try {
          await this.increaseClickAffiliateProduct({
            code: this.$route.query.affiliate_code
          })
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : error
          console.log(errorMessage)
        }
      }
    },

    handleAddProductToAffiliateSuccess () {
      this.setIsProductAffiliated(true)

      this.$Swal.fire({
        icon: 'success',
        title: 'Berhasil Menambahkan Produk',
        text: 'Produk berhasil ditambahkan ke daftar afiliasi. Promosikan tautan referalmu untuk mendapatkan komisi dari Paninti',
        confirmButtonText: 'OK'
      })
    },

    handleAddProductToAffiliateFailed () {
      this.$Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: 'Mohon maaf terjadi kesalahan, periksa kembali koneksi internet Anda dan coba beberapa saat lagi.',
        confirmButtonText: 'Kembali'
      })
    }
  },

  head () {
    return {
      title: this.data.meta_title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.data.meta_title
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.data.meta_title
        },
        {
          hid: 'description',
          name: 'description',
          content: this.data.meta_description
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.data.meta_description
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.productSEOImage
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.productMetaKeyword
        }
      ],
      script: this.metaData || []
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.setSelectedDetails({})
    next()
  },

  beforeRouteLeave (to, from, next) {
    this.setSelectedDetails({})
    next()
  }
}
