export default {
  data () {
    return {
      windowWidth: 0,
      windowHeight: 0
    }
  },

  computed: {
    normalDesktop () {
      return this.windowWidth >= 992
    },

    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 992
    }
  },

  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    })
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
