//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [mixins],

  async asyncData ({ store, params }) {
    let eventSaleSessions = []

    try {
      const resp = await store.dispatch('Contents/getOngoingEventSale', {
        data: {
          where_has: [
            {
              relation: 'event_sale',
              where: {
                type: 'event_sale',
                slug: params.id
              }
            }
          ]
        }
      })

      eventSaleSessions = resp.data.data.event_sale_sessions
      eventSaleSessions = eventSaleSessions.filter((sale) => {
        return sale.event_sale.slug === params.id
      })
    } catch (error) {
      console.log(error)
    }

    return {
      eventSaleSessions
    }
  },

  data () {
    return {
      search: ''
    }
  },

  computed: {
    ...mapState('Contents', ['searchSale']),
    eventSaleSession () {
      return Array.isArray(this.eventSaleSessions) && this.eventSaleSessions.length > 0
        ? this.eventSaleSessions[0]
        : null
    },

    eventSale () {
      return this.eventSaleSessions.length > 0 ? this.eventSaleSessions[0].event_sale : null
    },

    isSearch () {
      return this.search !== ''
    },
    breadCrumb () {
      const breadCrumb = [
        {
          path: '/'
        }
      ]

      if (this.eventSale) {
        breadCrumb.push(
        {
          name: this.eventSaleSession.event_sale.name
        })
      }

      return breadCrumb
    }
  },

  watch: {
    searchSale () {
      this.search = this.searchSale
    }
  },

  methods: {
    onSearch () {
      //
    }
  }
}
