export const isObjectNotEmptyOrNull = (obj) => {
  return !!(obj && Object.keys(obj).length)
}

export const filterNonNullValues = (obj) => {
  // Initialize a new object to store the filtered values
  const filteredObj = {}

  // Loop through each key-value pair in the object
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(obj)) {
    // Check if the value is not null
    if (value !== null) {
      // Add the key-value pair to the new object
      filteredObj[key] = value
    }
  }

  // Return the filtered object
  return filteredObj
}
