//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import VClamp from 'vue-clamp'
import {
  fileVariants,
  getDefaultImageFromFiles,
  getDefaultImgProductOnError
} from '@/utils/helpers/file-helper'
import {
  formatRupiah,
  generateRandomString
} from '@/utils/helpers/string-helper'
import { productImageNames } from '@/utils/helpers/product-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
export default {
  components: { VClamp },
  data () {
    return {
      qty: 1,
      selected: [],
      selectedImage: 0,
      onSubmit: false
    }
  },
  computed: {
    ...mapState('Cart', ['selectedProduct', 'isBuyNow']),
    ...mapState('User/address', ['location', 'userAddress', 'isCreated']),
    productImageNames () {
      return productImageNames
    },
    fileVariants () {
      return fileVariants
    },
    preOrderShow () {
      if (this.indexVariant === -1) {
        return this.selectedProduct.is_preorder
      }

      return !!this.selectedProduct.details[this.indexVariant].is_preorder
    },
    indexVariant () {
      if (this.getProductId()) {
        return this.selectedProduct.details.findIndex((detail) => {
          return detail.id === this.getProductId()
        })
      }
      return -1
    },
    productStore () {
      return this.selectedProduct ? this.selectedProduct.store : null
    },
    preOrderPeriode () {
      if (this.indexVariant === -1) {
        return this.selectedProduct.preorder_period
      }

      return this.selectedProduct.details[this.indexVariant].preorder_period
    },
    defaultVariant () {
      if (
        this.selectedProduct &&
        Object.keys(this.selectedProduct).length &&
        this.selectedProduct.details
      ) {
        let variant = this.selectedProduct.details.filter((variant) => {
          return variant.main
        })[0]
        if (!variant) {
          variant = this.selectedProduct.details[0]
        }
        const tempVariant = variant.name ? variant.name.split(',') : []
        return tempVariant
      } else {
        return null
      }
    },
    imageProductOriginal () {
      const image = []

      const imageProduct = this.selectedProduct.files.filter((file) => {
        return file.variant === 'original' && file.type === 'image'
      })
      imageProduct.map((img) => {
        image.push({ ...img, variant: null })
      })
      if (this.selectedProduct.type !== 'simple') {
        this.selectedProduct.details.map((detail) => {
          detail.files.map((file) => {
            if (file.variant === 'original' && file.type === 'image') {
              image.push({ ...file, variant: detail.id })
            }
          })
        })
      }
      return image
    },
    onFlashsale () {
      const productId = this.getProductId()
      const data = this.selectedProduct.details.filter((variant) => {
        return variant.id === productId
      })[0]
      if (data && data.event_sale_session) {
        return data
      }
      return false
    },
    isAvailable () {
      if (this.defaultVariant) {
        const variant = this.selected.join(',')
        const selectedVariant = this.selectedProduct.details.filter((v) => {
          return variant === v.name
        })[0]
        if (selectedVariant) {
          return selectedVariant.stock
        }
      }
      return false
    },
    selectedVariant () {
      if (this.isObjectNotEmptyOrNull(this.selectedProduct)) {
        if (this.selectedProduct.type === 'combination') {
          const temp = this.selected.join(', ')
          const variant = this.selectedProduct.details.filter((detail) => {
            return temp === detail.name
          })
          if (variant.length) {
            return variant[0].id
          } else {
            return null
          }
        } else {
          return this.selectedProduct.details[0].id || ''
        }
      } else {
        return ''
      }
    },
    productStock () {
      const temp = this.selected.filter((select) => {
        return select.length
      })
      if (this.selectedVariant) {
        return this.selectedProduct.details.filter((detail) => {
          return detail.id === this.selectedVariant
        })[0].stock
      } else if (temp.length && !this.selectedVariant) {
        return -1
      } else {
        return this.selectedProduct.stock
      }
    },
    validVariant () {
      let available = true
      this.selected.map((select) => {
        if (!select.length) {
          available = false
        }
      })
      return available
    },
    showStock () {
      if (this.selectedProduct.type === 'combination') {
        return this.validVariant
      } else {
        return true
      }
    },
    getDiscPrice () {
      let datas = []
      let price = this.selectedProduct.price
      let discounted = this.selectedProduct.discounted_price
      if (this.selectedVariant) {
        datas = this.selectedProduct.details.filter((detail) => {
          return this.selectedVariant === detail.id
        })[0]
        price = datas.price
        discounted = datas.discounted_price
      }
      return {
        price: this.formatRupiah(price.toString(), 'Rp'),
        show: !this.selectedVariant || price !== discounted
      }
    }
  },
  watch: {
    selectedVariant () {
      this.qty = 1
    }
  },
  mounted () {
    const modal = document.getElementById('popupCart')

    if (modal) {
      const vm = this
      modal.addEventListener('hidden.bs.modal', (event) => {
        vm.setIsBuyNow(false)
      })
    }
  },
  methods: {
    ...mapActions('Cart', [
      'addCart',
      'getCarts',
      'getCartsNavbar',
      'getEmptyCarts'
    ]),
    ...mapMutations('Cart', [
      'setSelectedProduct',
      'setSelectedVariant',
      'setIsBuyNow'
    ]),
    ...mapMutations('Base', ['SET_ERROR']),
    ...mapMutations('Product', ['setSelectedDetails']),
    generateRandomString,
    formatRupiah,
    getDefaultImgProductOnError,
    getDefaultImageFromFiles,
    isObjectNotEmptyOrNull,
    inputQty (value) {
      if (value > this.productStock) {
        this.qty = this.productStock
      }
      if (Number(value) < 0) {
        this.qty = 1
      }
    },
    getLabelStock (stock) {
      if (stock > 5) {
        return `Tersisa ${stock} buah`
      } else if (stock > 0 && stock <= 5) {
        return `${stock} Stok terakhir`
      } else if (stock === -1) {
        return 'Stok habis'
      } else {
        return 'Stok habis'
      }
    },
    handleChangeVariant () {
      if (this.selectedVariant) {
        const index = this.imageProductOriginal.findIndex((img) => {
          return img.variant === this.selectedVariant
        })
        if (index !== -1) {
          this.selectedImage = index
        }
      }
    },
    checkVariantDisabled (itemVar) {
      const details = this.selectedProduct.details
      const filledSelected = this.selected.filter(select => select !== '')

      if (this.selectedProduct.type === 'combination') {
        // more than 1 variant categories
        if (
          this.selectedProduct.variant_categories.length > 1 &&
          filledSelected.length
        ) {
          // get details that variant has and stock equal to zero
          const filteredDetailsEmpty = details.filter((detail) => {
            return (
              filledSelected.some(select => detail.name.includes(select)) &&
              detail.stock <= 0
            )
          })

          const checkExistEmpty = filteredDetailsEmpty.some((detail) => {
            return detail.name.includes(itemVar.name)
          })

          return checkExistEmpty
        } else if (this.selectedProduct.variant_categories.length === 1) {
          // only 1 variant categories
          const isVarEmpty = details.some((detail) => {
            return detail.name.includes(itemVar.name) && detail.stock <= 0
          })

          return isVarEmpty
        }
      }
      return false
    },
    getVariantLabel (variant) {
      if (variant === 'Color') {
        return 'Pilihan Warna'
      } else if (variant === 'Size') {
        return 'Pilihan Ukuran'
      } else if (variant === 'Flavor') {
        return 'Pilihan Rasa'
      } else {
        return null
      }
    },
    handleChangeColor (value) {
      this.selectedColor = value
    },
    getProductId () {
      if (this.selected && this.selected.length) {
        const variant = this.selected.join(',')
        const filteredVariant = this.selectedProduct.details.filter((v) => {
          return v.name === variant
        })

        return filteredVariant.length ? filteredVariant[0].id : ''
      }

      return ''
    },
    async handleAddToCart (direct) {
      this.onSubmit = true
      const productId = this.getProductId()

      try {
        const resp = await this.addCart({
          data: {
            store_id: this.selectedProduct.store
              ? this.selectedProduct.store.id
              : '',
            is_fulfilled: this.selectedProduct.is_fulfilled || false,
            product_detail_id: productId,
            quantity: this.qty,
            session_id: this.$cookies.get('session_id')
          }
        })
        localStorage.removeItem('cartItem')
        if (resp.data.status_code === 201) {
          if (!direct) {
            await this.setSelectedProduct({
              ...this.selectedProduct,
              quantity: this.qty
            })
            await this.setSelectedVariant(productId)
            await this.handleGetCart()
            this.$refs.closeButton.click()
            this.$refs.showAlert.click()
          } else {
            const cartItem = []
            cartItem.push(resp.data.data.cart.id)
            localStorage.cartItem = JSON.stringify(cartItem)

            const route = this.$auth.loggedIn ? '/checkout' : '/login'
            this.$refs.closeButton.click()
            setTimeout(() => {
              this.$router.push(route)
            }, 200)
          }
        }
      } catch (error) {
        this.$message.error(error.response.data.message)
        this.onSubmit = false
        console.log(error)
      }
      this.onSubmit = false
    },
    handleBuyNow () {
      if (this.$auth.loggedIn) {
        if (this.$auth.user.main_address || this.userAddress.length) {
          this.handleAddToCart(true)
        } else {
          this.$Swal
            .fire({
              title: 'Anda belum memiliki alamat',
              text: 'Silahkan tambah alamat untuk melanjutkan ke proses checkout',
              icon: 'warning',
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Tambah alamat'
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$refs.closeButton.click()
                setTimeout(() => {
                  this.$refs.buttonCreateAddress.click()
                }, 200)
              }
            })
        }
      } else {
        this.$refs.closeButton.click()
        setTimeout(() => {
          this.$router.push('/login')
        }, 200)
      }
    },
    async handleGetCart () {
      let payload = {
        session_id: this.$cookies.get('session_id'),
        order_by: { created_at: 'desc' }
      }
      if (this.$route.name === 'cart') {
        payload = await Object.assign({
          ...payload,
          conditions: {
            stock: 'available_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        const unAvail = await Object.assign({
          ...payload,
          conditions: {
            stock: 'unavailable_stock'
          },
          order_by: {
            updated_at: 'desc'
          }
        })
        try {
          await this.getEmptyCarts({ data: unAvail })
        } catch (error) {
          console.log(error)
        }
      }
      try {
        await this.getCartsNavbar({
          params: {
            session_id: payload.session_id
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    getAlert () {
      let error = ''
      const totalVar = this.selectedProduct.variant_categories.length
      this.selectedProduct.variant_categories.map((variant, index) => {
        error += `${variant.display_name} "${this.selected[index]}"`
        if (totalVar > 1) {
          if (index + 2 === totalVar) {
            error += ' dan '
          } else if (index + 1 !== totalVar) {
            error += ', '
          }
        }
      })
      return error
    },
    getPrice () {
      let price = this.selectedProduct.discounted_price
      if (this.selectedProduct.type === 'combination') {
        this.selectedProduct.details.map((detail) => {
          if (detail.id === this.getProductId()) {
            price =
              detail.discounted_price || this.selectedProduct.discounted_price
          }
        })
      }
      return price
    },
    handleResetState () {
      this.selected = []
      this.qty = 1
      this.onSubmit = false
    },
    handleClose () {
      this.handleResetState()
      this.$refs.modalBase.$refs.buttonClose.click()
    }
  }
}
