import { render, staticRenderFns } from "./UserVoucherSection.vue?vue&type=template&id=720783b8&scoped=true&"
import script from "./UserVoucherSection.vue?vue&type=script&lang=js&"
export * from "./UserVoucherSection.vue?vue&type=script&lang=js&"
import style0 from "./UserVoucherSection.vue?vue&type=style&index=0&id=720783b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720783b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/usr/src/app/components/base/Tab.vue').default,UserVoucherCardSkeleton: require('/usr/src/app/components/account/voucher/UserVoucherCardSkeleton.vue').default,UserVoucherCard: require('/usr/src/app/components/account/voucher/UserVoucherCard.vue').default,EmptyState: require('/usr/src/app/components/base/EmptyState.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,PopupDetailVoucher: require('/usr/src/app/components/home/cart/PopupDetailVoucher.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default})
