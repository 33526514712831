//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

export default {
  layout: 'account',
  middleware: ['user'],
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Notifikasi'
      }
    ]
    return {
      breadCrumb: breadcrumbs
    }
  },
  computed: {
    ...mapState('Base', ['dataBreadCrumbs'])
  },
  created () {
    this.setBreadCrumbs(this.breadCrumb)
  },
  methods: {
    ...mapMutations('Base', ['setBreadCrumbs'])
  }
}
