export const categoryFileNames = {
  logo: 'logo'
}

export const getCategoryLevelPathPageFromCategory = (category) => {
  if (!category) { return '' }

  return 'c' + category.level
}

export const getCategoryPagePathFromCategory = (category) => {
  if (!category) { return '' }

  return `/${getCategoryLevelPathPageFromCategory(category)}/${category.slug}`
}
