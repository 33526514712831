import { sortArrayByKey } from '@/utils/helpers/array-helper'
import { userGenders } from '@/utils/helpers/user-helper'
import { storeTypes } from '@/utils/helpers/store-helper'

export const fileTypes = {
  image: 'image',
  video: 'video',
  audio: 'audio',
  document: 'document'
}

export const fileVariants = {
  thumbnail: 'thumbnail',
  original: 'original'
}

export const defaultImages = {
  defaultProductImage: '/img/default/default_product_image.png',
  defaultStoreLogo: '/img/default/default_store_logo.png',
  defaultBrandLogo: '/img/default/default_brand_logo.png',
  defaultImageAvatarM: '/img/account/profile/default_avatar.png',
  defaultImageAvatarF: '/img/account/profile/default_avatar_female.png',
  defaultImageCategoryIcon: '/img/default/default_category_icon.jpg',
  defaultImageBanner: '/img/default/banner/default_landscape_image.jpg',
  defaultImageBannerFlashsale: '/img/home/flashsale/banner_flashsale_default.png'
}

export const getDefaultImageFromFiles = (files = [], name = null, variant = null, webp = false) => {
  if (files.length <= 0) { return '' }

  files = sortArrayByKey(
    files.filter(file => file.type === fileTypes.image),
    'sequence'
  )

  if (name) {
    files = files.filter(file => file.name === name)
  }

  if (variant) {
    const variantFiles = files.filter(file => file.variant === variant)
    if (variantFiles.length > 0) {
      files = variantFiles
    }
  }

  if (files.length <= 0) { return '' }

  return webp ? files[0].value + '.webp' : files[0].value
}

export const getDefaultImgOnError = (event, defaultImage = defaultImages.defaultProductImage) => {
  if (event.target.src && event.target.src.includes('.webp')) {
    event.target.src = event.target.src.replace('.webp', '')
  } else {
    event.target.src = defaultImage
  }
}

export const getDefaultImgProductOnError = (event) => {
  getDefaultImgOnError(event, defaultImages.defaultProductImage)
}

export const getDefaultImgBannerOnError = (event, isFlashsale = false) => {
  getDefaultImgOnError(
    event, isFlashsale ? defaultImages.defaultImageBannerFlashsale : defaultImages.defaultImageBanner
  )
}

export const getDefaultCategoryIconOnError = (event) => {
  getDefaultImgOnError(event, defaultImages.defaultImageCategoryIcon)
}

export const getDefaultUserAvatarOnError = (event, gender = userGenders.female) => {
  getDefaultImgOnError(
    event,
    gender === userGenders.male
      ? defaultImages.defaultImageAvatarM
      : defaultImages.defaultImageAvatarF
  )
}

export const getDefaultStoreLogoOnError = (event, storeType = storeTypes.storePartner) => {
  getDefaultImgOnError(
    event,
    storeType === storeTypes.storePartner
      ? defaultImages.defaultStoreLogo
      : defaultImages.defaultBrandLogo
  )
}
