//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDefaultImageFromFiles, getDefaultImgProductOnError } from '@/utils/helpers/file-helper'
import { formatRupiah } from '@/utils/helpers/string-helper';
export default {
  props: {
    listCart: {
      type: Array,
      default: () => []
    },
    qtyCart: {
      type: String,
      default: '0'
    }
  },
  methods: {
    getDefaultImageFromFiles,
    getDefaultImgProductOnError,
    formatRupiah,
    handleDeleteCart (id) {
      this.$emit('delete-cart', id)
    }
  }
}
